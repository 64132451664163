import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import utils from '../../common/utils';
import { tr } from '../../common/locale';
import Icon from '../../components/Icon';
import { statsState } from '../../_states/campaign.states';
import TooltipPopover from '../../components/TooltipPopover';
import { selectedBoardIdState } from '../../_states/lnusers.states';
import { userState } from '../../_states/user.states';
import CampaignExcludeMembers from './CampaignExcludeMembers';

function CampaignHeader({ campaign, board, isFromBoard }) {
    const stats = useRecoilValue(statsState);
    const setSelectedBoardId = useSetRecoilState(selectedBoardIdState);
    const user = useRecoilValue(userState);
    if (!campaign?.id) {
        return null;
    }

    const isRunningPeriod = () => {
        if (user?.quotas) {
            const isDay = user?.quotas?.schedule_days.indexOf(moment().isoWeekday() - 1) !== -1;
            const startTime = moment.tz(
                {
                    hour: user?.quotas?.schedule_start_hour,
                    minute: user?.quotas?.schedule_start_min,
                },
                user?.quotas?.schedule_timezone
            );
            const endTime = moment.tz(
                {
                    hour: user?.quotas?.schedule_end_hour,
                    minute: user?.quotas?.schedule_end_min,
                },
                user?.quotas?.schedule_timezone
            );
            return isDay && startTime.isBefore(moment()) && endTime.isAfter(moment());
        }
        return false;
    };

    return (
        <div className="campaign__subheader">
            <div className="campaign__overview">
                {campaign?.status === 'PLAYING' && utils.automationsHasAccess(user) && (
                    <div
                        className={`campaign__status ${
                            isRunningPeriod() ? 'campaign__status--processing' : 'campaign__status--stby'
                        }`}
                    >
                        <TooltipPopover
                            text={
                                isRunningPeriod()
                                    ? tr('Campaign is being played.')
                                    : tr('Campaign is paused. Currently out of schedule period.')
                            }
                        >
                            <Icon
                                size={isRunningPeriod() ? 24 : 28}
                                isProcessing={isRunningPeriod()}
                                icon={isRunningPeriod() ? 'cog-alt' : 'moon'}
                            />
                        </TooltipPopover>
                    </div>
                )}
                {campaign?.status === 'PLAYING' && !utils.automationsHasAccess(user) && (
                    <TooltipPopover text={tr('Campaign is paused')}>
                        <div className="campaign__status campaign__status--pause">
                            <Icon size={26} icon="pause" />
                        </div>
                    </TooltipPopover>
                )}
                {campaign?.status === 'DRAFT' && !campaign?.board && (
                    <TooltipPopover text={tr('Campaign is draft')}>
                        <div className="campaign__status campaign__status--draft">
                            <Icon size={24} icon="pen" />
                        </div>
                    </TooltipPopover>
                )}
                {campaign?.status === 'DRAFT' && campaign?.board && (
                    <TooltipPopover text={tr('Campaign is paused')}>
                        <div className="campaign__status campaign__status--pause">
                            <Icon size={26} icon="pause" />
                        </div>
                    </TooltipPopover>
                )}
                {campaign?.status === 'QUEUED' && (
                    <TooltipPopover text={tr('Campaign is queued')}>
                        <div className="campaign__status campaign__status--queued">
                            <Icon size={28} icon="clock" />
                        </div>
                    </TooltipPopover>
                )}
                {campaign?.status === 'FINISHED' && (
                    <TooltipPopover text={tr('Campaign is finished')}>
                        <div className="campaign__status campaign__status--finished">
                            <Icon size={28} icon="check" />
                        </div>
                    </TooltipPopover>
                )}
                {campaign?.status === 'ARCHIVED' && (
                    <TooltipPopover text={tr('Campaign is archived')}>
                        <div className="campaign__status campaign__status--archived">
                            <Icon size={28} icon="archive" />
                        </div>
                    </TooltipPopover>
                )}
                {(campaign?.board || campaign.status === 'ARCHIVED' || campaign?.status === 'FINISHED') && (
                    <>
                        <div className="campaign__overview__item">
                            <span>{tr('Total')}</span>
                            <strong>{stats?.source_count || campaign?.total_count}</strong>
                        </div>
                        {campaign.status !== 'ARCHIVED' && campaign?.status !== 'FINISHED' && (
                            <div className="campaign__overview__item">
                                <span>{tr('Processing')}</span>
                                <strong>
                                    {stats?.total - stats?.finished ||
                                        campaign?.processing_count - campaign?.process_count}
                                </strong>
                            </div>
                        )}
                        <div className="campaign__overview__item">
                            <span>{tr('Completed')}</span>
                            <strong>{stats?.finished || campaign?.process_count}</strong>
                        </div>
                    </>
                )}
            </div>

            {!isFromBoard && board && <CampaignExcludeMembers campaign={campaign} />}
            {!isFromBoard && board && (
                <NavLink
                    className="btn btn--link-primary btn--has-icon ml--30"
                    to="/board"
                    title={tr('Go to Pipeline')}
                    onClick={() => {
                        setSelectedBoardId(board);
                    }}
                >
                    <Icon icon="board" />
                    <span className="btn__label">{tr('Go to Pipeline')}</span>
                </NavLink>
            )}
            {!isFromBoard && !board && campaign?.status !== 'DRAFT' && (
                <TooltipPopover text={tr("You've deleted the Pipeline")}>
                    <div className="btn btn--link-primary disabled btn--has-icon ml--30">
                        <Icon icon="board" />
                        <span className="btn__label">{tr('Go to Pipeline')}</span>
                    </div>
                </TooltipPopover>
            )}
            {isFromBoard && campaign && (
                <NavLink className="btn btn--link-primary btn--has-icon ml--30" to={`/campaigns/${campaign.id}`}>
                    <Icon icon="play-square" />
                    <span className="btn__label">{tr('Go to Campaign')}</span>
                </NavLink>
            )}
        </div>
    );
}
export default CampaignHeader;
