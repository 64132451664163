import { useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Select, { components } from 'react-select';
import { templatesState } from '../../_states/template.states';
import { Colors } from '../lnuser/LabelsList';
import Switch from '../../components/Switch';
import Label from '../../components/Label';
import Input from '../../components/Input';
import Labels from '../lnuser/Labels';
import Icon from '../../components/Icon';
import CampaignMessagePreview from './CampaignMessagePreview';
import TooltipPopover from '../../components/TooltipPopover';
import Dropdown from '../../components/Dropdown';
import Flash from '../../components/Flash';
import { tr } from '../../common/locale';
import { labelsState } from '../../_states/label.states';
import { campaignState } from '../../_states/campaign.states';
import { userState } from '../../_states/user.states';
import utils from '../../common/utils';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

function CampaignSchedule({ getStepIndex }) {
    const stepIndex = getStepIndex('action_connect');
    const [campaign, setCampaign] = useRecoilState(campaignState);
    const labels = useRecoilValue(labelsState);
    const contentRef = useRef();
    const templates = useRecoilValue(templatesState);
    const contentAlternativeRef = useRef();
    const user = useRecoilValue(userState);
    const messageContainJobCompanyVars = utils.hasVariables(campaign.action_connect_message);
    let stepFrom = campaign.recipient_from_step_name;
    if (campaign.action_visit) {
        stepFrom = campaign.action_visit_step_name;
    }
    if (campaign.action_follow) {
        stepFrom = campaign.action_follow_step_name;
    }
    const messagesTemplates = templates?.filter((item) => item.type === 'message');
    return (
        <div className="campaign__config">
            <h1 className="title">
                {tr('Request Connection')}
                <div className="flex mbi">
                    {campaign.action_connect && (
                        <span className="seq-activated">
                            <Icon white icon="check" />
                            {tr('Sequence added')}
                        </span>
                    )}
                    {(user?.has_sales_nav || user?.is_premium) && (
                        <CampaignMessagePreview
                            campaign={campaign}
                            message={campaign.action_connect_message}
                            messageAlternate={campaign.action_connect_message_alternative}
                        />
                    )}
                </div>
            </h1>
            <div className="campaign__config-wrapper">
                <div className={`autom ${campaign.action_connect && 'activated'}`}>
                    <div className="autom-line stepped">
                        <div className="autom-step step-blue">{campaign.action_connect ? stepIndex : ''}</div>
                        <span className="bullet bullet-blue" />
                        <div className="autom-desc">{tr('Send the request to the members of the column')}</div>
                        <Input disabled value={stepFrom} />
                        {(campaign.action_visit || campaign.action_follow) && (
                            <>
                                <div>{tr('after')}</div>
                                <Dropdown
                                    className="small"
                                    btnFace="secondary"
                                    iconRight
                                    btnClassName="btn--bordered"
                                    label={tr('{{count}} day', {
                                        count: campaign.action_connect_wait_days,
                                    })}
                                    onClick={(e) => e.stopPropagation()}
                                    onDoubleClick={(e) => e.stopPropagation()}
                                >
                                    <Dropdown.Menu direction="s">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                                            <Dropdown.ButtonItem
                                                key={item}
                                                btnLabel={tr('{{count}} day', { count: item })}
                                                onClick={() => {
                                                    const newCamp = { ...campaign };
                                                    newCamp.action_connect_wait_days = item;
                                                    setCampaign(newCamp);
                                                }}
                                            />
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                        )}
                    </div>
                    {!user?.has_sales_nav && !user?.is_premium && (
                        <Flash color="blue">
                            {tr(
                                "You cannot add a personal note because you don't have a LinkedIn Premium account, which limits the number of notes you can send."
                            )}
                        </Flash>
                    )}
                    {(user?.has_sales_nav || user?.is_premium) && (
                        <>
                            <div className="autom-line mb--25">
                                {messagesTemplates?.length > 0 &&
                                    campaign.status !== 'ARCHIVED' &&
                                    campaign.status !== 'FINISHED' && (
                                        <Dropdown
                                            className="templates-link"
                                            btnFace="link-primary"
                                            noIcon
                                            label={tr('+ Use a template message')}
                                            onClick={(e) => e.stopPropagation()}
                                            onDoubleClick={(e) => e.stopPropagation()}
                                        >
                                            <Dropdown.Menu direction="s">
                                                {messagesTemplates?.map((element) => (
                                                    <Dropdown.ButtonItem
                                                        isSubscriptionPopover
                                                        key={element?.id}
                                                        btnLabel={element.title}
                                                        onClick={() => {
                                                            setCampaign({
                                                                ...campaign,
                                                                action_connect_message: element.content,
                                                                action_connect_message_alternate: element.alternative,
                                                            });
                                                        }}
                                                    />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                            </div>

                            <div className="autom-line column">
                                {utils.variableButtons(contentRef, true, campaign.action_connect_message, (mess) =>
                                    setCampaign({ ...campaign, action_connect_message: mess })
                                )}
                                <Input
                                    className="message invite"
                                    type="textarea"
                                    value={campaign.action_connect_message}
                                    disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                                    maxlength="300"
                                    ref={contentRef}
                                    onChange={(e) =>
                                        setCampaign({ ...campaign, action_connect_message: e.target.value })
                                    }
                                />
                                {messageContainJobCompanyVars && (
                                    <>
                                        <h4>{tr('Alternative message')}</h4>
                                        <p className="normal">
                                            {tr(
                                                'The alternative message allows you to send a coherent message if one of the variables - "Company Name", "Job Title", "Icebreaker", "Industry" or "Location" - are not filled in on the user\'s profile.'
                                            )}
                                        </p>
                                        {utils.variableButtons(
                                            contentAlternativeRef,
                                            false,
                                            campaign.action_connect_message_alternative,
                                            (mess) =>
                                                setCampaign({ ...campaign, action_connect_message_alternative: mess })
                                        )}
                                        <Input
                                            className="message invite"
                                            type="textarea"
                                            disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                                            value={campaign.action_connect_message_alternative}
                                            maxlength="300"
                                            ref={contentAlternativeRef}
                                            onChange={(e) => {
                                                setCampaign({
                                                    ...campaign,
                                                    action_connect_message_alternative: e.target.value,
                                                });
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    <div className="autom-line bulleted mt--40">
                        <span className="bullet" />
                        <div className="flex gap--10">
                            <Switch
                                disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                                text={tr(
                                    'Skip connection requests for Open Profiles and send them directly the first message.'
                                )}
                                id="skipconnectionrequest"
                                checked={campaign.action_connect_skip_openprofile}
                                onChange={(val) => setCampaign({ ...campaign, action_connect_skip_openprofile: val })}
                            />
                            <TooltipPopover
                                text={tr(
                                    "Open profiles are members who accept messages from people who aren't in their connections."
                                )}
                            >
                                <Icon size={16} icon="info" fill fillColor="#3ec2ff" />
                            </TooltipPopover>
                        </div>
                    </div>
                    <div className="autom-line bulleted mt--40">
                        <span className="bullet" />
                        <div>{tr('When request is sent, move member to column')}</div>
                        <Input
                            value={campaign.action_connect_step_requested_name}
                            disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            onChange={(e) =>
                                setCampaign({ ...campaign, action_connect_step_requested_name: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className={`autom ${campaign.action_connect && 'activated'}`}>
                    <div className="autom-line stepped">
                        <div className="autom-step step-purple">{campaign.action_connect ? stepIndex + 1 : ''}</div>
                        <span className="bullet bullet-purple" />
                        <div className="autom-desc">
                            {tr('If connection request is accepted, move the member column')}
                        </div>
                    </div>
                    <div className="autom-line mb--40">
                        <div>{tr('From')}</div>
                        <Input disabled value={campaign.action_connect_step_requested_name} />
                        <div>{tr('To')}</div>
                        <Input
                            disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            value={campaign.action_connect_step_accepted_name}
                            onChange={(e) =>
                                setCampaign({ ...campaign, action_connect_step_accepted_name: e.target.value })
                            }
                        />
                    </div>
                    <div className="autom-line bulleted">
                        <span className="bullet bullet-purple" />
                        <div className="autom-desc">{tr('And add labels to member (optional)')}</div>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isDisabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            onChange={(selected) => {
                                setCampaign({ ...campaign, action_connect_labels: selected.map((item) => item.value) });
                            }}
                            components={{ Option: LabelOption }}
                            options={labels?.map((label) => ({
                                value: label.id,
                                name: label.name,
                                color: label.color,
                                label: label.name,
                            }))}
                            defaultValue={campaign?.action_connect_labels.map((current) => {
                                const label = labels.find((item) => item.id === current);
                                if (!label) {
                                    return undefined;
                                }
                                return {
                                    value: label.id,
                                    name: label.name,
                                    color: label.color,
                                    label: label.name,
                                };
                            })}
                            styles={{
                                multiValue: (styles, { data }) => ({
                                    ...styles,
                                    backgroundColor: Colors[data.color].light,
                                }),
                            }}
                        />
                        <Labels isClosable context="btn-addlabel" disableSelect />
                    </div>
                </div>
                <div className={`autom ${campaign.action_connect && 'activated'}`}>
                    <div className="autom-line stepped">
                        <div className={`autom-step step-green ${campaign.action_connect && 'step-large'}`}>
                            {campaign.action_connect ? stepIndex + 2 : ''}
                        </div>
                        <span className="bullet bullet-green" />
                        <div className="autom-desc">
                            {tr('If the connection message is answered, move the member column')}
                        </div>
                    </div>

                    <div className="autom-line mb--40">
                        <div>{tr('From')}</div>
                        <Input disabled value={campaign.action_connect_step_requested_name} />
                        <div>{tr('To')}</div>
                        <Input
                            value={campaign.action_answered_step_name}
                            disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            onChange={(e) => {
                                const newCamp = { ...campaign };
                                newCamp.action_answered_step_name = e.target.value;
                                setCampaign(newCamp);
                            }}
                        />
                    </div>

                    <div className="autom-line bulleted">
                        <span className="bullet bullet-green" />
                        <div className="autom-desc">{tr('And add labels to member (optional)')}</div>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            isDisabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selected) => {
                                setCampaign({
                                    ...campaign,
                                    action_connect_answered_labels: selected.map((item) => item.value),
                                });
                            }}
                            components={{ Option: LabelOption }}
                            options={labels?.map((label) => ({
                                value: label.id,
                                name: label.name,
                                color: label.color,
                                label: label.name,
                            }))}
                            defaultValue={campaign?.action_connect_answered_labels.map((current) => {
                                const label = labels.find((item) => item.id === current);
                                if (!label) {
                                    return null;
                                }
                                return {
                                    value: label.id,
                                    name: label.name,
                                    color: label.color,
                                    label: label.name,
                                };
                            })}
                            styles={{
                                multiValue: (styles, { data }) => ({
                                    ...styles,
                                    backgroundColor: Colors[data.color].light,
                                }),
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`autom ${
                        campaign.action_connect && campaign.action_connect_ignore_delete && 'activated'
                    }`}
                >
                    <div className="autom-line nopad mb--30">
                        <Switch
                            disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            text={tr('Cancel connection request if connection request is ignored for')}
                            id="deleteconnectionrequest"
                            checked={campaign.action_connect_ignore_delete}
                            onChange={(val) => setCampaign({ ...campaign, action_connect_ignore_delete: val })}
                        />
                        <Dropdown
                            disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            className="small"
                            btnFace="secondary"
                            iconRight
                            btnClassName="btn--bordered"
                            label={tr('{{count}} day', { count: campaign.action_connect_ignore_days })}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            <Dropdown.Menu direction="s">
                                {[5, 10, 15, 20, 30].map((item) => (
                                    <Dropdown.ButtonItem
                                        key={item}
                                        btnLabel={tr('{{count}} day', { count: item })}
                                        onClick={() => {
                                            setCampaign({ ...campaign, action_connect_ignore_days: item });
                                        }}
                                    />
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="autom-line stepped">
                        <div className="autom-step step-red">
                            {campaign.action_connect && campaign.action_connect_ignore_delete ? stepIndex + 3 : ''}
                        </div>
                        <span className="bullet bullet-red" />
                        <div className="autom-desc">{tr('Move the member column')}</div>
                    </div>
                    <div className="autom-line">
                        <div>{tr('From')}</div>
                        <Input disabled value={campaign.action_connect_step_requested_name} />
                        <div>{tr('To')}</div>
                        <Input
                            disabled={campaign.status === 'ARCHIVED' || campaign.status === 'FINISHED'}
                            value={campaign.action_connect_step_ignored_name}
                            onChange={(e) =>
                                setCampaign({ ...campaign, action_connect_step_ignored_name: e.target.value })
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CampaignSchedule;
