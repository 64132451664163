import { useState, useEffect } from 'react';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import useLnUsersActions from '../../_actions/lnusers.actions';
import useBoardAction from '../../_actions/board.actions';

function BulkEnrichAction({ boardId, laneId, selectedUsers, closeCb }) {
    const lnUsersActions = useLnUsersActions();
    const boardActions = useBoardAction();
    const [usersToProcess, setUsersToProcess] = useState(selectedUsers);

    const bulkEnrich = () => {
        lnUsersActions.enrich(usersToProcess);
    };

    useEffect(() => {
        if (boardId && laneId && !usersToProcess) {
            boardActions.getUsers(boardId, laneId).then((users) => setUsersToProcess(users));
        }
    }, []);

    return (
        <Modal
            closeCb={closeCb}
            labelValidate={tr('Enrich')}
            defaultIsOpen
            title={tr('Enrich profile of selected members')}
            validateCb={bulkEnrich}
        >
            {tr(
                "We are going to look for email addresses of selected members. Only members with a company will be enriched. Connections you've not updated won't have a company, so they will be ignored. It may take up to 10 minutes before members profiles get updated."
            )}
        </Modal>
    );
}
export default BulkEnrichAction;
