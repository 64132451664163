import { atom } from 'recoil';

export const filterProcessedState = atom({
    key: 'filterProcessedState',
    default: true,
});

export const loadingState = atom({
    key: 'loadingCampaignState',
    default: true,
});

export const launchingState = atom({
    key: 'launchingState',
    default: false,
});

export const loadingStatsState = atom({
    key: 'loadingStatsState',
    default: false,
});

export const autocompleteState = atom({
    key: 'autocompleteCampaignState',
    default: [],
});

export const removedMembersState = atom({
    key: 'removedMembersState',
    default: [],
});

export const statsState = atom({
    key: 'statsState',
    default: null,
});

export const sourcecountState = atom({
    key: 'sourcecountState',
    default: null,
});

export const loadingTasksState = atom({
    key: 'loadingTasksState',
    default: false,
});

export const tasksState = atom({
    key: 'tasksState',
    default: null,
});

const defaultCampaign = {
    name: 'New campaign',
    users: [],
    recipient_from: 'leads',
    recipient_from_list_id: null,
    recipient_from_list_filter: null,
    recipient_from_label_id: null,
    recipient_from_step_name: 'Recipients',

    board_name: '',

    action_visit: false,
    action_visit_step_name: 'Visited',

    action_follow: false,
    action_follow_step_name: 'Followed',

    action_answered_step_name: 'Member answered',
    action_error_step_name: 'Error',

    action_connect: false,
    action_connect_message: '',
    action_connect_message_alternate: '',
    action_connect_wait_days: 0,
    action_connect_step_requested_name: 'Connection requested',
    action_connect_step_accepted_name: 'Request accepted',
    action_connect_step_ignored_name: 'Request canceled',
    action_connect_labels: [],
    action_connect_answered_labels: [],
    action_connect_ignore_days: 10,
    action_connect_ignore_delete: false,
    action_connect_skip_openprofile: false,

    action_message_1: false,
    action_message_1_content: '',
    action_message_1_alternate: '',
    action_message_1_wait_days: 0,
    action_message_1_step_sent_name: 'Message sent',
    action_message_1_labels: [],
    action_message_1_ignore_days: 3,

    action_message_2: false,
    action_message_2_content: '',
    action_message_2_alternate: '',
    action_message_2_step_sent_name: 'Follow-up message 1 sent',
    action_message_2_labels: [],
    action_message_2_ignore_days: 3,

    action_message_3: false,
    action_message_3_content: '',
    action_message_3_alternate: '',
    action_message_3_step_sent_name: 'Follow-up message 2 sent',
    action_message_3_labels: [],
    action_message_3_ignore_days: 3,

    action_message_4: false,
    action_message_4_content: '',
    action_message_4_alternate: '',
    action_message_4_step_sent_name: 'Follow-up message 3 sent',
    action_message_4_labels: [],
    action_message_4_ignore_days: 3,
};
export const selectedCampaignState = atom({
    key: 'selectedCampaignState',
    default: JSON.parse(JSON.stringify(defaultCampaign)),
});
export const campaignState = atom({
    key: 'campaignState',
    default: defaultCampaign,
});

export const campaignsState = atom({
    key: 'campaignsState',
    default: null,
});
