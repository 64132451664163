import { useEffect, useState, useRef, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getRecoil } from 'recoil-nexus';
import cl from 'classnames';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { tr } from '../../common/locale';
import Input from '../../components/Input';
import Label from '../../components/Label';
import Button from '../../components/Button';
import AddBoard from '../board/AddBoard';
import TooltipPopover from '../../components/TooltipPopover';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import useLnusersActions from '../../_actions/lnusers.actions';
import useUserActions from '../../_actions/user.actions';
import useLeadsActions from '../../_actions/leads.actions';
import { Colors } from '../lnuser/LabelsList';
import BulkActions from './BulkActions';
import BulkStatus from './BulkStatus';
import Labels from '../lnuser/Labels';
import Welcome from './Welcome';
import AppsumoReviews from './AppsumoReviews';
import ConnectionLine from './ConnectionLine';

import {
    connectionsState,
    filterNameState,
    boardsState,
    // filterCompanyState,
    filterJobState,
    filterLocationState,
    filterHeadlineState,
    filterLabelsState,
    filterNoLabelState,
    boardFilterState,
    filterConnectionsState,
    filterInboxState,
    selectedUsersState,
    lastSelectState,
    bulkSendProgressState,
    bulkInviteProgressState,
    csvExportState,
    loadingState,
} from '../../_states/lnusers.states';
import { labelsState } from '../../_states/label.states';
import { notifsState, initialConvSyncState, userState, firstSyncState } from '../../_states/user.states';
import Tooltip from '../../components/Tooltip';
import SubscriptionPopover from '../../components/SubscriptionPopover';

function Connections() {
    const actions = useLnusersActions();
    const leadsActions = useLeadsActions();
    const userActions = useUserActions();
    const bulkMessageRef = useRef();
    const syncRef = useRef();
    const user = useRecoilValue(userState);
    const scrollRef = useRef();
    const bulkInviteRef = useRef();
    const [personalMenuShow, setPersonalMenu] = useState(localStorage.getItem('menuPersonalState') || 'show');
    const [labelMenuShow, setLabelMenu] = useState(localStorage.getItem('menuLabelState') || 'show');
    const [boardMenuShow, setBoardMenu] = useState(localStorage.getItem('menuBoardState') || 'show');
    const [inboxMenuShow, setInboxMenu] = useState(localStorage.getItem('menuInboxState') || 'show');
    const loading = useRecoilValue(loadingState);
    const boards = useRecoilValue(boardsState);
    const bulkSendProgress = useRecoilValue(bulkSendProgressState);
    const bulkInviteProgress = useRecoilValue(bulkInviteProgressState);
    const [networkFilter, setnetworkFilter] = useState('');
    const connections = useRecoilValue(connectionsState);
    const [filterName, setFilterName] = useRecoilState(filterNameState);
    const csvExport = useRecoilValue(csvExportState);
    // const [filterCompany, setFilterCompany] = useRecoilState(filterCompanyState);
    const filterJob = useRecoilValue(filterJobState);
    const labels = useRecoilValue(labelsState);
    const initialConvSync = useRecoilValue(initialConvSyncState);
    const [selectedUsers, setSelectedUsers] = useRecoilState(selectedUsersState);
    const [searchText, setSearchText] = useState('');
    const filterLocation = useRecoilValue(filterLocationState);
    const filterHeadline = useRecoilValue(filterHeadlineState);
    const firstSync = useRecoilValue(firstSyncState);
    const setLastSelect = useSetRecoilState(lastSelectState);

    const [filterConnections, setFilterConnections] = useRecoilState(filterConnectionsState);
    const [filterInbox, setFilterInbox] = useRecoilState(filterInboxState);
    const [filterLabels, setFilterLabels] = useRecoilState(filterLabelsState);
    const [filterNoLabel, setFilterNoLabel] = useRecoilState(filterNoLabelState);
    const [filterBoard, setFilterBoard] = useRecoilState(boardFilterState);
    useEffect(() => {
        leadsActions.list(true);
    }, []);

    const selectUserCb = (e, connection) => {
        e.stopPropagation();
        const connects = getRecoil(connectionsState);
        const selectedUsersList = getRecoil(selectedUsersState);
        const lastSelect = getRecoil(lastSelectState);
        const isSelected = selectedUsersList?.find((item) => item.id === connection.id);
        const indexOfElem = connects.items.findIndex((item) => item.id === connection.id);
        const newSelectedUsers = [...selectedUsersList];

        if (!isSelected) {
            if (e.nativeEvent.shiftKey && lastSelect !== -1) {
                const min = indexOfElem < lastSelect ? indexOfElem : lastSelect;
                const max = indexOfElem < lastSelect ? lastSelect : indexOfElem;
                for (let i = min; i < max; i++) {
                    if (!newSelectedUsers.find((item) => item.id === connects.items[i].id)) {
                        newSelectedUsers.push(connects.items[i]);
                    }
                }
            }
            newSelectedUsers.push(connection);
        } else {
            if (e.nativeEvent.shiftKey && lastSelect !== -1) {
                const min = indexOfElem < lastSelect ? indexOfElem : lastSelect;
                const max = indexOfElem < lastSelect ? lastSelect : indexOfElem;
                for (let i = min; i < max; i++) {
                    const indexCurrent = newSelectedUsers.findIndex((item) => item.id === connects.items[i].id);
                    if (indexCurrent !== -1) {
                        newSelectedUsers.splice(indexCurrent, 1);
                    }
                }
            }
            const currentIndex = selectedUsersList?.findIndex((item) => item.id === connection.id);
            if (currentIndex !== -1) {
                newSelectedUsers.splice(currentIndex, 1);
            }
        }
        setSelectedUsers(newSelectedUsers);
        setLastSelect(indexOfElem);
    };

    const search = (value) => {
        if ((filterConnections === 'conversations' || filterConnections === 'archivedconv') && value) {
            setFilterBoard('');
            setFilterLabels([]);
        }
        setFilterName(value);
    };
    const debouncedSearch = useCallback(debounce(search, 300), []);
    useEffect(() => {
        if (initialConvSync) {
            actions.list(true);
        }
        if (scrollRef?.current) {
            scrollRef.current.pageLoaded = 0;
            scrollRef.current.el.scrollTop = 0;
        }
    }, [
        filterName,
        // filterCompany,
        filterJob,
        filterLocation,
        filterHeadline,
        filterLabels,
        filterConnections,
        filterBoard,
        filterInbox,
        initialConvSync,
    ]);
    useEffect(() => {
        setSelectedUsers([]);
        userActions.updateBrowsing();
    }, [filterConnections]);
    useEffect(() => {
        if (bulkSendProgress === 'done') {
            bulkMessageRef?.current?.close();
        }
    }, [bulkSendProgress]);
    useEffect(() => {
        if (bulkInviteProgress === 'done') {
            bulkInviteRef?.current?.close();
        }
    }, [bulkInviteProgress]);
    useEffect(
        () => () => {
            setSelectedUsers([]);
            setFilterName('');
            if (filterLabels.length > 0) {
                setFilterLabels([]);
            }
        },
        []
    );
    const toggleNoLabel = () => {
        setFilterNoLabel(!filterNoLabel);
        setFilterLabels([]);
    };
    const toggleLabel = (label) => {
        setFilterNoLabel(false);
        const newFilterLabels = filterLabels ? [...filterLabels] : [];
        if (newFilterLabels.indexOf(label.id) !== -1) {
            newFilterLabels.splice(newFilterLabels.indexOf(label.id), 1);
        } else {
            newFilterLabels.push(label.id);
        }
        setFilterLabels(newFilterLabels);
    };

    const toggleBoard = (board) => {
        if (board && board.id !== filterBoard) {
            setFilterBoard(board.id);
            if (filterConnections === 'conversations' || filterConnections === 'archivedconv') {
                setFilterConnections('all');
            }
        } else {
            setFilterBoard('');
        }
    };

    useEffect(() => {
        localStorage.setItem('menuInboxState', inboxMenuShow);
        localStorage.setItem('menuPersonalState', personalMenuShow);
        localStorage.setItem('menuLabelState', labelMenuShow);
        localStorage.setItem('menuBoardState', boardMenuShow);
    }, [inboxMenuShow, personalMenuShow, labelMenuShow, boardMenuShow]);
    const searchByKeywords =
        (filterConnections === 'archivedconv' || filterConnections === 'conversations') && filterName;
    const hasMore =
        !connections || connections.count > connections.items.length || (searchByKeywords && connections.count === 25);
    const notifs = useRecoilValue(notifsState);
    const hasUnread = notifs.inbox > 0;
    const hasRemind = notifs.reminders > 0;
    const hasArchivedUnread = notifs.archived > 0;
    const hasReceivedInvites = notifs.received_invitations > 0;
    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--connections">
                <div className="list__col list__col--checkbox">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--info list__col--fill">
                    <div className="list__fake__item" />
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--action list__col--label">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--action list__col--board">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--mini list__col--center">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--action">
                    <div className="list__fake__item" />
                </div>
            </div>
        );
    }
    const updateFilterConnections = (filter) => {
        if (filter === filterConnections) {
            actions.list(true);
        } else {
            setFilterConnections(filter);
            if (filter === 'conversations' || filter === 'archivedconv') {
                setFilterBoard('');
                setFilterLabels([]);
            }
        }
    };
    let labelNetwork = tr('All Members');
    let iconNetwork = 'users';
    if (networkFilter === 'SENT') {
        labelNetwork = tr('Connections sent');
        iconNetwork = 'user-arrow-right';
    } else if (networkFilter === 'REFUSED') {
        labelNetwork = tr('Connections declined');
        iconNetwork = 'user-arrow-right-close';
    } else if (networkFilter === 'PENDING') {
        labelNetwork = tr('Connections received');
        iconNetwork = 'user-arrow-left';
    } else if (networkFilter === 'IGNORED') {
        labelNetwork = tr('Connections I ignored');
        iconNetwork = 'user-arrow-left-close';
    } else if (networkFilter === 'archived') {
        labelNetwork = tr('Hidden');
        iconNetwork = 'hide';
    }
    useEffect(() => {
        if (networkFilter) {
            updateFilterConnections(networkFilter);
        }
    }, [networkFilter]);
    const networkFilterActive =
        filterConnections === 'all' ||
        filterConnections === 'SENT' ||
        filterConnections === 'REFUSED' ||
        filterConnections === 'PENDING' ||
        filterConnections === 'IGNORED' ||
        filterConnections === 'archived';
    useEffect(() => {
        if (networkFilterActive) {
            setnetworkFilter(filterConnections);
        }
    }, []);

    let bulkActionsList = ['message', 'archive', 'invite', 'read', 'unread', 'enrich', 'labels', 'boards', 'hide'];
    if (filterConnections === 'archivedconv') {
        bulkActionsList = ['inbox'];
    } else if (filterConnections === 'connections') {
        bulkActionsList = ['message', 'enrich', 'labels', 'boards', 'process', 'disconnect', 'hide'];
    } else if (filterConnections === 'contacts') {
        bulkActionsList = ['invite', 'message', 'enrich', 'labels', 'boards', 'process', 'hide'];
    } else if (filterConnections === 'processed') {
        bulkActionsList = ['unprocess', 'hide'];
    } else if (filterConnections === 'all') {
        bulkActionsList = ['message', 'invite', 'enrich', 'labels', 'boards', 'hide'];
    } else if (filterConnections === 'reminders') {
        bulkActionsList = ['unremind', 'message', 'invite', 'enrich', 'labels', 'boards', 'hide'];
    } else if (filterConnections === 'SENT') {
        bulkActionsList = ['withdraw_invite', 'enrich', 'labels', 'boards', 'hide'];
    } else if (filterConnections === 'PENDING') {
        bulkActionsList = ['accept_invite', 'enrich', 'labels', 'boards', 'hide'];
    } else if (filterConnections === 'REFUSED' || filterConnections === 'IGNORED') {
        bulkActionsList = ['synchronize', 'enrich', 'labels', 'boards', 'hide'];
    } else if (filterConnections === 'archived') {
        bulkActionsList = ['show'];
    }
    const csvExp = () => {
        if (selectedUsers.length > 0) {
            return null;
        }
        return (
            <>
                {user?.plan?.product !== 'SCRAPE' && (
                    <Tooltip
                        className="fullh flex"
                        text={tr('Download CSV of all members of current view')}
                        direction="w"
                    >
                        <Button
                            icon="download"
                            type="tab"
                            className="btn--tab--white"
                            isLoading={csvExport}
                            label="CSV"
                            onClick={() => {
                                actions.csv();
                            }}
                        />
                    </Tooltip>
                )}
                {user?.plan?.product === 'SCRAPE' && (
                    <SubscriptionPopover force>
                        <Button icon="download" type="tab" className="btn--tab--white" label="CSV" />
                    </SubscriptionPopover>
                )}
            </>
        );
    };
    return (
        <div className="content connections">
            <Welcome />
            <AppsumoReviews />
            <div className="content__sidebar">
                <div className="content__sidebar__header">
                    <div className="content__sidebar__header__title">
                        <h1 className="title">{tr('Kanbox')}</h1>
                    </div>
                </div>
                <div className="content__sidebar__body">
                    <div
                        className={`content__sidebar__body__section ${
                            inboxMenuShow === 'hide' ? 'section-reduce' : 'section-show'
                        } ${
                            inboxMenuShow === 'hide' &&
                            (filterConnections === 'conversations' || filterConnections === 'archivedconv')
                                ? 'section-selected'
                                : ''
                        }`}
                        role="button"
                        tabIndex={0}
                        onClick={() => (inboxMenuShow === 'show' ? setInboxMenu('hide') : setInboxMenu('show'))}
                        onKeyDown={() => null}
                    >
                        <div className="content__sidebar__body__section__header-action">
                            <h5 className="title">{tr('Conversations')}</h5>
                            {inboxMenuShow === 'hide' ? <Icon icon="plus" size={12} /> : ''}
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            <li>
                                <Button
                                    isNotification={hasUnread}
                                    icon="inbox"
                                    iconSize={20}
                                    active={filterConnections === 'conversations'}
                                    type="secondary"
                                    label={tr('Inbox') + (hasUnread ? ` (${notifs.inbox})` : '')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateFilterConnections('conversations');
                                    }}
                                />
                            </li>
                            <li>
                                <Button
                                    icon="inbox-archive"
                                    iconSize={20}
                                    active={filterConnections === 'archivedconv'}
                                    type="secondary"
                                    label={tr('Archived') + (hasArchivedUnread ? ` (${notifs.archived})` : '')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateFilterConnections('archivedconv');
                                    }}
                                />
                            </li>
                        </ul>
                    </div>
                    <div className="content__sidebar__body__section--scroll">
                        <div
                            className={`content__sidebar__body__section ${
                                personalMenuShow === 'hide' ? 'section-reduce' : 'section-show'
                            } ${
                                personalMenuShow === 'hide' &&
                                (filterConnections === 'connections' ||
                                    filterConnections === 'contacts' ||
                                    filterConnections === 'all' ||
                                    filterConnections === 'processed')
                                    ? 'section-selected'
                                    : ''
                            }`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                                personalMenuShow === 'show' ? setPersonalMenu('hide') : setPersonalMenu('show')
                            }
                            onKeyDown={() => null}
                        >
                            <div className="content__sidebar__body__section__header-action">
                                <h5 className="title">{tr('My Network')}</h5>
                                {personalMenuShow === 'hide' ? <Icon icon="plus" size={12} /> : ''}
                            </div>
                            <ul className="content__sidebar__body__section__filters">
                                <li>
                                    <div className="btn--group-sidebar">
                                        <Button
                                            icon={iconNetwork}
                                            iconSize={20}
                                            active={networkFilterActive}
                                            type="secondary"
                                            className="pr--40"
                                            label={labelNetwork}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                updateFilterConnections(networkFilter || 'all');
                                            }}
                                        />
                                        <Dropdown
                                            isBtnNotification={hasReceivedInvites}
                                            btnFace="transparent"
                                            btnFaceIconSize={18}
                                            btnSize="mini"
                                            onClick={(e) => e.stopPropagation()}
                                            onDoubleClick={(e) => e.stopPropagation()}
                                        >
                                            <Dropdown.Menu direction="sw">
                                                <Dropdown.ButtonItem
                                                    btnLabel={tr('All Members')}
                                                    btnItemIconSize={18}
                                                    active={filterConnections === 'all'}
                                                    onClick={() => setnetworkFilter('all')}
                                                />
                                                <Dropdown.ButtonItem
                                                    btnLabel={`${tr('Connections sent')} ${
                                                        notifs.sent_invitations ? `(${notifs.sent_invitations})` : ''
                                                    }`}
                                                    btnItemIconSize={18}
                                                    active={networkFilter === 'SENT'}
                                                    onClick={() => setnetworkFilter('SENT')}
                                                />
                                                <Dropdown.ButtonItem
                                                    btnLabel={tr('Connections declined')}
                                                    btnItemIconSize={18}
                                                    active={networkFilter === 'REFUSED'}
                                                    onClick={() => setnetworkFilter('REFUSED')}
                                                />
                                                <Dropdown.ButtonItem
                                                    isBtnItemNotification={hasReceivedInvites}
                                                    btnLabel={`${tr('Connections received')} ${
                                                        notifs.received_invitations
                                                            ? `(${notifs.received_invitations})`
                                                            : ''
                                                    }`}
                                                    btnItemIconSize={18}
                                                    active={networkFilter === 'PENDING'}
                                                    onClick={() => setnetworkFilter('PENDING')}
                                                />
                                                <Dropdown.ButtonItem
                                                    btnLabel={tr('Connections I ignored')}
                                                    btnItemIconSize={18}
                                                    active={networkFilter === 'IGNORED'}
                                                    onClick={() => setnetworkFilter('IGNORED')}
                                                />
                                                <Dropdown.ButtonItem
                                                    btnLabel={tr('Hidden')}
                                                    btnItemIconSize={18}
                                                    active={networkFilter === 'archived'}
                                                    onClick={() => setnetworkFilter('archived')}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </li>
                                <li>
                                    <Button
                                        icon="clock"
                                        iconSize={20}
                                        isNotification={hasRemind}
                                        active={filterConnections === 'reminders'}
                                        type="secondary"
                                        label={tr('Reminders') + (hasRemind ? ` (${notifs.reminders})` : '')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateFilterConnections('reminders');
                                        }}
                                    />
                                </li>
                                <li>
                                    <Button
                                        icon="user"
                                        iconSize={20}
                                        active={filterConnections === 'connections'}
                                        type="secondary"
                                        label={tr('Connections')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateFilterConnections('connections');
                                        }}
                                    />
                                </li>
                                <li>
                                    <Button
                                        icon="contact-circle"
                                        iconSize={20}
                                        active={filterConnections === 'contacts'}
                                        type="secondary"
                                        label={tr('Contacts')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateFilterConnections('contacts');
                                        }}
                                    />
                                </li>
                                <li>
                                    <Button
                                        icon="archive-circle"
                                        iconSize={20}
                                        active={filterConnections === 'processed'}
                                        type="secondary"
                                        label={tr('Archived')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateFilterConnections('processed');
                                        }}
                                    />
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`content__sidebar__body__section ${
                                labelMenuShow === 'hide' ? 'section-reduce' : 'section-show'
                            }  ${labelMenuShow === 'hide' && filterLabels.length > 0 ? 'section-selected' : ''}`}
                            role="button"
                            tabIndex={0}
                            onClick={() => (labelMenuShow === 'show' ? setLabelMenu('hide') : setLabelMenu('show'))}
                            onKeyDown={() => null}
                        >
                            <div className="content__sidebar__body__section__header-action">
                                <h5 className="title">{tr('Labels')}</h5>
                                {labelMenuShow === 'hide' ? <Icon className="expand-icon" icon="plus" size={12} /> : ''}
                                {filterLabels?.length >= 2 && labelMenuShow === 'show' && (
                                    <Button
                                        className="unselect"
                                        type="link"
                                        label={tr('Unselect all')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setFilterLabels([]);
                                        }}
                                    />
                                )}
                                {labels?.length > 0 && labelMenuShow === 'show' && (
                                    <Labels context="filter" disableSelect />
                                )}
                            </div>
                            <div className="content__sidebar__body__section__filters">
                                {labels?.length === 0 && <Labels context="list-nolabel" disableSelect />}
                                {labels?.length > 0 && (
                                    <Button
                                        className="no-label"
                                        active={filterNoLabel}
                                        icon="no-label"
                                        iconSize={18}
                                        type="secondary"
                                        label={tr('No label')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleNoLabel();
                                        }}
                                    />
                                )}
                                {labels?.map((label, index) => {
                                    const labelFilt = (
                                        <div
                                            role="button"
                                            tabIndex={0}
                                            key={label.id}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleLabel(label);
                                            }}
                                            onKeyDown={() => null}
                                        >
                                            <Label
                                                isSelectable
                                                iconSize={20}
                                                label={label.name}
                                                selected={filterLabels?.indexOf(label.id) !== -1}
                                                // color={Colors[label.color].light}
                                                iconColor={Colors[label.color].dark}
                                            />
                                        </div>
                                    );
                                    if (index >= 2) {
                                        return <SubscriptionPopover key={label.id}>{labelFilt}</SubscriptionPopover>;
                                    }
                                    return labelFilt;
                                })}
                            </div>
                        </div>

                        <div
                            className={`content__sidebar__body__section ${
                                boardMenuShow === 'hide' ? 'section-reduce' : 'section-show'
                            }  ${boardMenuShow === 'hide' && filterBoard ? 'section-selected' : ''}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                                boardMenuShow === '' || boardMenuShow === 'show'
                                    ? setBoardMenu('hide')
                                    : setBoardMenu('show')
                            }
                            onKeyDown={() => null}
                        >
                            <div className="content__sidebar__body__section__header-action">
                                <h5 className="title">{tr('Pipelines')}</h5>
                                {boardMenuShow === 'hide' ? <Icon icon="plus" size={12} /> : ''}
                                {boards?.length >= 1 && boardMenuShow === 'show' && <AddBoard context="filter" />}
                            </div>
                            <div className="content__sidebar__body__section__filters">
                                {boards?.length === 0 && <AddBoard context="sidebar" />}

                                {boards?.map((board) => (
                                    <div key={board.id}>
                                        <Button
                                            iconSize={18}
                                            icon="board"
                                            type="secondary"
                                            active={board.id === filterBoard}
                                            label={board.name}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleBoard(board);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content__body">
                {user?.plan?.product === 'SCRAPE' && (
                    <div className="service-message service-message--info">
                        <div>
                            {tr('The synchronization of your Inbox is no longer active.')}&nbsp;
                            <Link to="/subscription">{tr('Please upgrade your subscription.')}</Link>
                        </div>
                    </div>
                )}
                {(filterConnections === 'conversations' || filterConnections === 'archivedconv') && (
                    <div className="connections__header">
                        <Button
                            type="tab"
                            label={tr('All')}
                            active={filterInbox === 'all'}
                            onClick={() => setFilterInbox('all')}
                        />
                        <Button
                            type="tab"
                            label={tr('Unread')}
                            active={filterInbox === 'unread'}
                            onClick={() => setFilterInbox('unread')}
                        />
                        <Button
                            type="tab"
                            label={tr('Last message sent by member')}
                            active={filterInbox === 'unanswered'}
                            onClick={() => setFilterInbox('unanswered')}
                        />
                        <Button
                            type="tab"
                            label={tr('Last message sent by me')}
                            active={filterInbox === 'unansweredcontact'}
                            onClick={() => setFilterInbox('unansweredcontact')}
                        />
                        <Button
                            type="tab"
                            label={tr('Never answered')}
                            active={filterInbox === 'neveranswered'}
                            onClick={() => setFilterInbox('neveranswered')}
                        />
                        <div className="connections__header__actions">
                            {!user?.conversations_sync_start_at && !firstSync && (
                                <TooltipPopover
                                    ref={syncRef}
                                    className="fullh flex"
                                    text={tr(
                                        'Your Linkedin account is being synchronized for the first time. Some data may be missing.'
                                    )}
                                >
                                    <Button
                                        className="fullh px--20 btn--processing"
                                        type="bulk"
                                        iconSize={20}
                                        icon="cog-alt"
                                        label={tr('Synchronizing your contacts')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            syncRef?.current?.toggle();
                                        }}
                                    />
                                </TooltipPopover>
                            )}
                            {csvExp()}
                        </div>
                    </div>
                )}
                <div
                    className={cl(
                        'list__filter',
                        { 'list__filter--activated': searchText },
                        { 'list__filter--actions': selectedUsers?.length > 0 }
                    )}
                >
                    <BulkActions
                        selectedUsers={selectedUsers}
                        resetSelectedUsers={() => setSelectedUsers([])}
                        bulkActionsList={bulkActionsList}
                    />
                    <BulkStatus />
                    <Input
                        isFilter
                        isNoBorder
                        icon="search"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            debouncedSearch(e.target.value);
                        }}
                        placeholder={
                            filterConnections === 'conversations' || filterConnections === 'archivedconv'
                                ? tr('Search by keywords')
                                : tr('Search by name or headline')
                        }
                        type="search"
                        isFocusedDefault={false}
                    />
                    {!(filterConnections === 'conversations' || filterConnections === 'archivedconv') && csvExp()}
                </div>
                <div className="list__header">
                    <div className="list__cols">
                        <div className="list__col list__col--checkbox" />
                        <div className="list__col list__col--fill list__col--info">{tr('Members')}</div>
                        <div className="list__col list__col--action list__col--label">{tr('Labels')}</div>
                        <div className="list__col list__col--action list__col--board">{tr('Pipeline | Column')}</div>
                        <div className="list__col list__col--mini list__col--center">{tr('Notes')}</div>
                        <div className="list__col list__col--action">{tr('Reminder')}</div>
                    </div>
                </div>
                <div
                    id="connectionsContent"
                    className={`connections--list list--pattern ${
                        loading || (connections && connections?.items.length > 0) ? '' : 'list--empty'
                    }`}
                >
                    {loading && (!connections || connections?.items.length === 0) && fakeLoader}
                    {connections && connections?.items.length > 0 ? (
                        <InfiniteScroll
                            dataLength={connections?.items.length || 0}
                            next={actions.list}
                            className="list list--clickable"
                            hasMore={hasMore}
                            loader={fakeLoader}
                            ref={scrollRef}
                            scrollableTarget="connectionsContent"
                        >
                            {connections &&
                                connections.items.map((connection, index) => (
                                    <ConnectionLine
                                        key={connection.id}
                                        connection={connection}
                                        index={index}
                                        selectUserCb={selectUserCb}
                                        isSelected={!!selectedUsers?.find((item) => item.id === connection.id)}
                                    />
                                ))}
                        </InfiniteScroll>
                    ) : (
                        <>
                            {!loading &&
                                ((filterConnections === 'conversations' && !filterBoard) || !filterLabels ? (
                                    <div className="empty">
                                        <Icon isEmpty icon="empty-inbox" size={400} />
                                        <h1>{tr("That's all !")}</h1>
                                    </div>
                                ) : (
                                    <div className="empty">
                                        <Icon isEmpty icon="empty-connection" size={400} />
                                        <h1>{tr('Nothing here')}</h1>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Connections;
