import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { searchesState, searchesOfLeadState, selectedLeadState } from '../../_states/leads.states';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import Flash from '../../components/Flash';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import ConfirmButton from '../../components/ConfirmButton';
import Icon from '../../components/Icon';
import avatar from '../../images/avatar.svg';
import Tooltip from '../../components/Tooltip';
import useLeadsActions from '../../_actions/leads.actions';
import Select from '../../components/Select';
import Input from '../../components/Input';
import AddInboxModal from './AddInboxModal';
import utils from '../../common/utils';
import { userState, upgradeState } from '../../_states/user.states';

function Lead({ search, lead, closeCb }) {
    const modalRef = useRef();
    const modalMoveRef = useRef();
    const user = useRecoilValue(userState);
    const setUpgrade = useSetRecoilState(upgradeState);
    const actions = useLeadsActions();
    const [listName, setListName] = useState('');
    const searches = useRecoilValue(searchesState);
    const searchesOfLead = useRecoilValue(searchesOfLeadState);
    const setSelectedLead = useSetRecoilState(selectedLeadState);
    const [exportState, setExportState] = useState(null);
    const [selectedSearch, setSelectedSearch] = useState(null);

    useEffect(() => {
        if (lead) {
            modalRef.current.open();
            if (!searches) {
                actions.list(true);
            }
            actions.getSearchesOfLead(lead.id);
        }
    }, [lead]);
    const searchTypeLabel = (searchParam) => {
        const origin = searchParam?.is_salesnav ? 'Sales Navigator' : 'Linkedin';
        if (searchParam?.is_salesnav || searchParam?.is_free_search) {
            return tr('Imported from {{origin}} on {{date}}', {
                date: moment(searchParam?.created_at).format('LLL'),
                origin,
            });
        }
        return tr('Created on {{date}}', {
            date: moment(searchParam?.created_at).format('LLL'),
        });
    };

    const lists = searches?.items?.filter((item) => item.name !== 'Individual scrapes');
    return (
        <Modal isFull noHeader closesWithEscape ref={modalRef} closeCb={closeCb} labelValidate={tr('Close')} noFooter>
            {exportState && (
                <AddInboxModal
                    searchId="all"
                    closeCb={() => setExportState(null)}
                    exportState={exportState}
                    count={1}
                    lead={lead}
                />
            )}
            <div className="content lnuser lnuser--lead">
                <div className="lnuser__sidebar left">
                    <div className="lnuser__sidebar__section-scroll">
                        <div className="lnuser__sidebar__section">
                            <Avatar
                                className="lnuser__user__avatar"
                                src={lead?.picture || avatar}
                                alt={`${lead?.firstname_cleaned} ${lead?.lastname_cleaned}`}
                            />
                        </div>

                        {(lead?.is_premium || lead?.is_open_profile || lead?.is_opentowork) && (
                            <div className="lnuser__sidebar__section__extra-info">
                                {lead?.is_premium && (
                                    <div className="privilege privilege--large privilege--premium">
                                        {tr('LinkedIn Premium')}
                                    </div>
                                )}
                                {lead?.is_open_profile && (
                                    <div className="privilege privilege--large privilege--open-profile">
                                        {tr('Open profile')}
                                    </div>
                                )}
                                {lead?.is_opentowork && (
                                    <div className="privilege privilege--large privilege--open-to-work">
                                        {tr('Open to work')}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="lnuser__sidebar__section lnuser__sidebar__section__info">
                            <div className="lnuser__sidebar__section__user-info">
                                <h1 className="lnuser__user__name">
                                    {lead?.firstname_cleaned} {lead?.lastname_cleaned}
                                </h1>
                                {lead?.headline && <div className="lnuser__user__headline">{lead?.headline}</div>}
                                {(lead?.location || lead?.country) && (
                                    <div className="lnuser__user__location">{lead?.location || lead?.country}</div>
                                )}
                                {lead?.email && (
                                    <div className="lnuser__user__email">
                                        <a href={`mailto:${lead?.email}`}>{lead?.email}</a>
                                    </div>
                                )}
                                {lead?.uploaded_email && (
                                    <div className="lnuser__user__email">
                                        <a href={`mailto:${lead?.uploaded_email}`}>{lead?.uploaded_email}</a>
                                    </div>
                                )}
                                {lead?.email_enrich && (
                                    <div className="lnuser__user__email">
                                        <a href={`mailto:${lead?.email}`}>{lead?.email_enrich}</a>
                                    </div>
                                )}
                                {lead?.phone && (
                                    <div className="lnuser__user__phone">
                                        <a href={`tel:${lead?.phone}`}>{lead?.phone}</a>
                                    </div>
                                )}
                                {lead?.uploaded_phone && (
                                    <div className="lnuser__user__phone">
                                        <a href={`tel:${lead?.uploaded_phone}`}>{lead?.uploaded_phone}</a>
                                    </div>
                                )}
                                {lead?.uploaded_icebreaker && (
                                    <div className="lnuser__user__icebreaker">
                                        <a href={`tel:${lead?.uploaded_icebreaker}`}>{lead?.uploaded_icebreaker}</a>
                                    </div>
                                )}
                                {lead?.skills && lead?.skills.length > 0 && (
                                    <div className="mt--50">
                                        <h5>{tr('Skills')}</h5>
                                        <div className="lnuser__user__skills">
                                            <div className="lnuser__user__skills-container">
                                                {lead?.skills.map((skill) => (
                                                    <div className="skill">
                                                        <span className="ellipsis">{skill}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {lead?.summary && <div className="lnuser__user__summary">{lead?.summary}</div>}
                                <div className="lnuser__sidebar__section__stats">
                                    <div className="box box--contact">
                                        <span className="box__value" title={lead?.connections}>
                                            {lead?.connections !== null ? lead?.connections : 'NA'}
                                        </span>
                                        <h5 className="box__label">{tr('Connections')}</h5>
                                    </div>
                                    <div className="box box--contact">
                                        <span className="box__value" title={lead?.follower}>
                                            {lead?.follower != null ? lead?.follower : 'NA'}
                                        </span>
                                        <h5 className="box__label">{tr('Followers')}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="lnuser__sidebar__section__current">
                                {lead?.company && (
                                    <>
                                        <div className="lnuser__sidebar__section__current__info">
                                            <h5>{tr('Current Company')}</h5>
                                            <div className="lnuser__company__card">
                                                <div className="lnuser__company__card__icon">
                                                    <Icon icon="company" size={60} />
                                                </div>
                                                <div className="lnuser__company__card__info">
                                                    <div className="lnuser__user__company">{lead?.company}</div>
                                                    {lead?.company_website && (
                                                        <div className="lnuser__user__company__website">
                                                            <a
                                                                href={lead?.company_website}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {lead?.company_website}
                                                            </a>
                                                        </div>
                                                    )}
                                                    <div className="lnuser__company__card__info__section">
                                                        {lead?.company_type && <div>{lead?.company_type}</div>}
                                                        {lead?.company_industry && (
                                                            <div className="lnuser__user__industry">
                                                                {lead?.company_industry}
                                                            </div>
                                                        )}
                                                        <div className="lnuser__company__card__meta">
                                                            {lead?.company_year_founded && (
                                                                <span>
                                                                    &bull; {tr('Founded in')}{' '}
                                                                    {lead?.company_year_founded}
                                                                </span>
                                                            )}
                                                            {lead?.company_employee_count && (
                                                                <span>
                                                                    &bull; {lead?.company_employee_count}{' '}
                                                                    {tr('employees')}
                                                                </span>
                                                            )}
                                                            {lead?.company_location && (
                                                                <span>&bull; {lead?.company_location}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="lnuser__company__card__info__section">
                                                        {lead?.company_description && (
                                                            <div>{lead?.company_description}</div>
                                                        )}
                                                        {lead?.company_specialties && (
                                                            <div>
                                                                {tr('Specialities:')}{' '}
                                                                {lead?.company_specialties.join(', ')}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {lead?.company_linkedin && (
                                                        <div className="lnuser__user__company__website">
                                                            <a
                                                                href={lead?.company_linkedin}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {tr('LinkedIn company page')}
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {lead?.job && (
                                    <>
                                        <div className="lnuser__sidebar__section__current__info">
                                            <h5>{tr('Current Position')}</h5>
                                            <div className="lnuser__user-position__card">
                                                <div className="lnuser__user-position__card__icon">
                                                    <Icon icon="case" size={60} />
                                                </div>
                                                <div className="lnuser__user-position__card__info">
                                                    <div className="lnuser__user__job">{lead?.job}</div>
                                                    <div className="lnuser__company__card__info__section">
                                                        <div className="lnuser__company__card__meta">
                                                            {(lead?.year_position || lead?.month_position) && (
                                                                <span>
                                                                    &bull; {tr('Stayed in position during')}{' '}
                                                                    {lead?.year_position
                                                                        ? tr('{{count}} year', {
                                                                              count: lead?.year_position,
                                                                          })
                                                                        : ''}{' '}
                                                                    {lead?.month_position
                                                                        ? tr('{{count}} month', {
                                                                              count: lead?.month_position,
                                                                          })
                                                                        : ''}
                                                                </span>
                                                            )}
                                                            {(lead?.year_company || lead?.month_company) && (
                                                                <span>
                                                                    &bull; {tr('Stayed in company during')}{' '}
                                                                    {lead?.year_company
                                                                        ? tr('{{count}} year', {
                                                                              count: lead?.year_company,
                                                                          })
                                                                        : ''}{' '}
                                                                    {lead?.month_company
                                                                        ? tr('{{count}} month', {
                                                                              count: lead?.month_company,
                                                                          })
                                                                        : ''}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="lnuser__company__card__info__section">
                                                        <div>{lead?.job_description}</div>
                                                        {lead?.industy && <div>{lead?.industy}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lnuser__sidebar right">
                    <div className="lnuser__sidebar__header">
                        <Tooltip direction="w" text={tr('You can use "ESC" key to close.')}>
                            <Button isRound type="link" icon="close" onClick={() => modalRef?.current.close()} />
                        </Tooltip>
                    </div>
                    <div className=" lnuser__sidebar__section-scroll">
                        <div className="lnuser__sidebar__section">
                            {search && (
                                <div className="lnuser__import__meta">
                                    <div className="flex mbi">
                                        <Icon icon={utils.searchTypeIcon(search)} />
                                        <h1 className="bold ellipsis">{search?.name}</h1>
                                    </div>
                                    <div>{searchTypeLabel(search)}</div>
                                </div>
                            )}
                            <div className="lnuser__import__meta" />
                            {search && (
                                <div className="lnuser__match">
                                    {lead?.is_match && search?.is_salesnav && (
                                        <div className="lnuser__match__label lnuser__match__label--ok">MATCH</div>
                                    )}
                                    {!lead?.is_match && search?.is_salesnav && (
                                        <div className="lnuser__match">
                                            <div className="lnuser__match__label lnuser__match__label--nok">
                                                NOT A MATCH
                                            </div>
                                            <div className="lnuser__match__reason">
                                                {lead?.no_match_reasons.join(', ')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {searchesOfLead && (
                                <div className="lnuser__import__meta">
                                    <div className="flex mbi">
                                        {!search && <h1 className="bold ellipsis">{tr('Lists')}</h1>}
                                        {search && <h5 className="bold ellipsis mt--40">{tr('Duplicated in')}</h5>}
                                    </div>
                                    {searchesOfLead
                                        .filter((current) => current.id !== search?.id)
                                        .map((current) => (
                                            <div key={current.id}>
                                                <Link
                                                    to={`/searches/${current.id}`}
                                                    onClick={() => {
                                                        setSelectedLead(null);
                                                        modalRef?.current.close();
                                                    }}
                                                >
                                                    <div className="flex mbi">
                                                        <Icon icon={utils.searchTypeIcon(current)} />
                                                        <h4 className="bold ellipsis">{current?.name}</h4>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                        {!lead?.lnuser && (
                            <div className="lnuser__sidebar__section">
                                <div className="lnuser__actions__header">
                                    <h5>{tr('Inbox')}</h5>
                                </div>
                                <Button
                                    type="secondary-dark"
                                    icon="inbox-plus"
                                    label={tr('Add to my Inbox')}
                                    onClick={() => {
                                        if (user?.plan.product === 'SCRAPE') {
                                            setUpgrade(true);
                                        } else {
                                            setExportState('inbox');
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {!lead?.lnuser && (
                            <div className="lnuser__sidebar__section">
                                <div className="lnuser__actions__header">
                                    <h5>{tr('Pipeline')}</h5>
                                </div>
                                <Button
                                    type="secondary-dark"
                                    icon="board-plus"
                                    label={tr('Add to a pipeline')}
                                    onClick={() => {
                                        if (user?.plan.product === 'SCRAPE') {
                                            setUpgrade(true);
                                        } else {
                                            setExportState('pipeline');
                                        }
                                    }}
                                />
                            </div>
                        )}
                        <div className="lnuser__sidebar__section">
                            <div className="lnuser__actions__header">
                                <h5>{tr('Actions')}</h5>
                            </div>
                            <div className="lnuser__actions__list">
                                <Button
                                    icon={lead?.is_match ? 'close-circle' : 'check-circle'}
                                    type="secondary-dark"
                                    label={lead?.is_match ? 'Set as NOT A MATCH' : 'Set as MATCH'}
                                    onClick={() => actions.toggleMatch(search, lead)}
                                />
                                {lead?.linkedin_public_id && lead?.linkedin_public_id !== 'UNKNOWN' && (
                                    <a
                                        className="btn btn--has-icon btn--secondary-dark"
                                        href={`https://www.linkedin.com/in/${lead?.linkedin_public_id}`}
                                        type="secondary-blue"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="linkedin" />
                                        <span className="btn__label">{tr('See profile on LinkedIn')}</span>
                                    </a>
                                )}
                                {lead?.salesnavigator_url && (
                                    <a
                                        className="btn btn--has-icon btn--secondary-dark"
                                        href={lead?.salesnavigator_url}
                                        type="secondary-blue"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Icon icon="sales-navigator" />
                                        <span className="btn__label">{tr('See profile on Sales Navigator')}</span>
                                    </a>
                                )}
                            </div>

                            <div className="lnuser__actions__list">
                                <Modal
                                    ref={modalMoveRef}
                                    title={tr('Move to a list')}
                                    disabled={!selectedSearch && !listName}
                                    overflow
                                    footer={
                                        <div className="modal__footer">
                                            <Button
                                                label={tr('Cancel')}
                                                type="transparent"
                                                onClick={() => modalMoveRef.current.close()}
                                            />

                                            <Button
                                                type="white"
                                                isBordered
                                                label={tr('Move and go to list')}
                                                onClick={
                                                    !listName
                                                        ? () => {
                                                              actions.changeList(search, lead, selectedSearch, true);
                                                              modalMoveRef?.current.close();
                                                          }
                                                        : () => {
                                                              actions.createList(search, lead, listName, true);
                                                              modalMoveRef?.current.close();
                                                          }
                                                }
                                                disabled={!selectedSearch && !listName}
                                            />
                                            <Button
                                                type="primary"
                                                label={tr('Move')}
                                                onClick={
                                                    !listName
                                                        ? () => {
                                                              actions.changeList(search, lead, selectedSearch, false);
                                                              modalMoveRef?.current.close();
                                                          }
                                                        : () => {
                                                              actions.createList(search, lead, listName, false);
                                                              modalMoveRef?.current.close();
                                                          }
                                                }
                                                disabled={!selectedSearch && !listName}
                                            />
                                        </div>
                                    }
                                >
                                    <Flash wrap color="blue" className="mb--15">
                                        <strong>{tr('Information:')}</strong>
                                        <br />
                                        {tr(
                                            'Duplicated leads will be moved from this list, but their occurrences in other lists will not be affected.'
                                        )}
                                    </Flash>
                                    {lists?.length === 0 && (
                                        <p className="red mt--30 mb--15">{tr('You do not have any list yet.')}</p>
                                    )}
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder={tr('Select a list')}
                                        onChange={(selected) => {
                                            setSelectedSearch(selected.value);
                                        }}
                                        isDisabled={lists?.length === 0}
                                        options={lists?.map((current) => ({
                                            value: current.id,
                                            name: current.name,
                                            label: current.name,
                                        }))}
                                    />
                                    <div className="my--20 center">OR</div>

                                    <Input
                                        label={tr('Create a new list')}
                                        placeholder={tr('List name')}
                                        value={listName}
                                        onChange={(e) => setListName(e.target.value)}
                                    />
                                </Modal>
                                <Button
                                    type="secondary-dark"
                                    icon="folder-arrow"
                                    label={tr('Move to a list')}
                                    isLoading={false}
                                    disabled={false}
                                    onClick={() => {
                                        setSelectedSearch(null);
                                        modalMoveRef?.current.open();
                                    }}
                                />
                                <ConfirmButton
                                    type="secondary-dark"
                                    icon="trash-can"
                                    label={tr('Delete from list')}
                                    confirmLabel={tr('Are you sure you want to remove this lead from all the lists?')}
                                    onConfirm={() => {
                                        actions.removeFromList('all', lead);
                                        modalRef?.current.close();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Lead;
