import { useEffect, useCallback, useState, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useRecoilValue, useRecoilState } from 'recoil';
import EmojiPicker from '../lnuser/EmojiPicker';
import { tr } from '../../common/locale';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import Tooltip from '../../components/Tooltip';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import useTemplatesActions from '../../_actions/template.actions';
import useUserActions from '../../_actions/user.actions';
import { templatesState, loadingState, templateState } from '../../_states/template.states';
import { userState } from '../../_states/user.states';
import utils from '../../common/utils';

function Templates() {
    const contentRef = useRef();
    const alternativeRef = useRef();
    const actions = useTemplatesActions();
    const userActions = useUserActions();
    const templates = useRecoilValue(templatesState);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [alternative, setAlternative] = useState('');
    const user = useRecoilValue(userState);
    const [type, setType] = useState('message');
    const [template, setTemplate] = useRecoilState(templateState);
    const loadingList = useRecoilValue(loadingState);
    const [filter, setFilter] = useState('');
    const search = (event) => {
        setFilter(event.target.value);
    };
    useEffect(() => {
        userActions.updateBrowsing();
    }, []);

    const debouncedSearch = useCallback(debounce(search, 300), []);
    const reset = () => {
        setTitle(template?.title || '');
        setType(template?.type || 'message');
        setContent(template?.content || '');
        setAlternative(template?.alternative || '');
    };
    const invitLimit = user?.has_sales_nav || user?.is_premium ? 300 : 200;
    useEffect(() => {
        reset();
    }, [template]);
    useEffect(() => {
        if (type === 'invitation' && content?.length > invitLimit) {
            setContent(content.substring(0, invitLimit));
        }
    }, [type]);
    const onEmojiClick = (emojiData, ref, message, setMessage) => {
        if (ref.current?.inputRef?.current?.selectionStart) {
            setMessage(
                `${message.slice(0, ref.current?.inputRef?.current?.selectionStart)}${emojiData.emoji}${message.slice(
                    ref.current?.inputRef?.current?.selectionStart
                )}`
            );
        } else {
            setMessage(`${message}${emojiData.emoji}`);
        }
    };
    const renderTemplate = (current) => (
        <div
            className={`btn btn--has-icon btn--template ${current.id === template?.id ? 'active' : ''}`}
            role="button"
            tabIndex={0}
            onClick={() => setTemplate(current)}
            key={current.id}
            onKeyDown={() => null}
        >
            <Icon icon="document" />
            <span className="btn__label">{current.title}</span>
            <Dropdown
                btnFaceIconSize={16}
                onClick={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
            >
                <Dropdown.Menu>
                    <Dropdown.ConfirmButtonItem
                        label={tr('Delete')}
                        confirmLabel={tr('Are you sure you want to delete this template?')}
                        onConfirm={() => actions.deleteTemplate(current.id)}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    const templateAttachments = (ref, message, setMessage) => (
        <div className="templates__section__footer">
            <div className="templates__section__attachments">
                <EmojiPicker placement="top-center" onEmojiClick={(e) => onEmojiClick(e, ref, message, setMessage)} />
            </div>
            <div className="templates__section__attachments">
                <Tooltip direction="n" text={tr('Coming Soon')}>
                    <Button disabled icon="paperclip" label={tr('Files')} type="secondary" />
                </Tooltip>
            </div>
            <div className="templates__section__attachments">
                <Tooltip direction="n" text={tr('Coming Soon')}>
                    <Button disabled icon="picture" label={tr('Images')} type="secondary" />
                </Tooltip>
            </div>
            <div className="templates__section__attachments">
                <Tooltip direction="n" text={tr('Coming Soon')}>
                    <Button disabled icon="video" label={tr('Videos')} type="secondary" />
                </Tooltip>
            </div>
        </div>
    );
    const messageContainJobCompanyVars = utils.hasVariables(content);
    return (
        <div className="content templates">
            <div className="content__sidebar content__sidebar--huge">
                <div className="content__sidebar__header">
                    <div className="content__sidebar__header__title">
                        <h1 className="title">{tr('Message Templates')}</h1>
                        <Button
                            icon="plus"
                            size="small"
                            label={tr('New')}
                            onClick={() => setTemplate(null)}
                            disabled={
                                (!template && (title || content)) ||
                                (template && (template?.title !== title || template?.content !== content))
                            }
                        />
                    </div>
                </div>
                <div className="content__sidebar__body">
                    <div className="content__sidebar__body__section">
                        <Input
                            isFilter
                            type="search"
                            icon="search"
                            size="small"
                            customIconSize={16}
                            onChange={debouncedSearch}
                            placeholder={tr('Type in a search')}
                            isFocusedDefault={false}
                        />
                        <h5 className="title">{tr('Message Templates')}</h5>

                        {loadingList && <Icon fill icon="load" />}
                        {templates
                            ?.filter(
                                (item) =>
                                    item.type === 'message' && item.title.toLowerCase().includes(filter.toLowerCase())
                            )
                            .map((current) => renderTemplate(current))}

                        {!loadingList &&
                            templates?.filter(
                                (item) =>
                                    item.type === 'message' && item.title.toLowerCase().includes(filter.toLowerCase())
                            ).length === 0 && <div>{tr('No message templates')}</div>}
                        <div className="content__sidebar__body__section__separator" />

                        <h5 className="title">{tr('Connection request templates')}</h5>

                        {loadingList && <Icon fill icon="load" />}
                        {templates
                            ?.filter(
                                (item) =>
                                    item.type === 'invitation' &&
                                    item.title.toLowerCase().includes(filter.toLowerCase())
                            )
                            .map((current) => renderTemplate(current))}
                        {!loadingList &&
                            templates?.filter(
                                (item) =>
                                    item.type === 'invitation' &&
                                    item.title.toLowerCase().includes(filter.toLowerCase())
                            ).length === 0 && <div>{tr('No connection request templates')}</div>}
                    </div>
                </div>
            </div>
            <div className="content__body">
                <div className="templates__header">
                    <div className="templates__type">
                        <span className="no-wrap">{tr('Type:')}</span>
                        <Dropdown
                            className="templates__type-button"
                            iconRight
                            btnFace="secondary"
                            label={
                                // eslint-disable-next-line no-nested-ternary
                                !type ? tr('Select') : type === 'invitation' ? tr('Connection request') : tr('Message')
                            }
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            <Dropdown.Menu direction="s">
                                <Dropdown.ButtonItem
                                    btnLabel={tr('Connection request')}
                                    onClick={() => {
                                        setType('invitation');
                                    }}
                                />
                                <Dropdown.ButtonItem
                                    btnLabel={tr('Message')}
                                    onClick={() => {
                                        setType('message');
                                    }}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Input
                        size="large"
                        isNoBorder
                        isNoPadding
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={tr('Type in a message template name')}
                        value={title}
                        isFocusedDefault
                    />
                    {type === 'invitation' && <div className="templates__counter_whar" />}
                </div>
                <div className="templates__sections">
                    <div className="templates__section">
                        <div className="templates__section__header">
                            <h2>{tr('Message')}</h2>
                        </div>
                        <div className="templates__section__options">
                            {utils.variableButtons(contentRef, true, content, setContent)}
                        </div>
                        {type === 'invitation' && (
                            <div className="templates__section__counter-char">
                                <span className={content?.length > invitLimit - 20 ? ' red' : ''}>
                                    {content?.length || 0} / {invitLimit}
                                </span>
                            </div>
                        )}
                        <div className="templates__section__message">
                            <Input
                                size="large"
                                type="textarea"
                                maxlength={type === 'invitation' ? `${invitLimit}` : undefined}
                                ref={contentRef}
                                onChange={(e) => setContent(e.target.value)}
                                placeholder={tr('Type in a message template content')}
                                value={content}
                            />
                        </div>
                        {templateAttachments(contentRef, content, setContent)}
                    </div>
                    {messageContainJobCompanyVars && (
                        <div className="templates__section">
                            <div className="templates__section__header">
                                <h2>{tr('Alternative message')}</h2>
                                <p>
                                    {tr(
                                        'The alternative message allows you to send a coherent message if one of the variables - "Company Name", "Job Title", "Icebreaker", "Industry" or "Location" - are not filled in on the user\'s profile.'
                                    )}
                                </p>
                            </div>
                            <div className="templates__section__options">
                                {utils.variableButtons(alternativeRef, false, alternative, setAlternative)}
                            </div>
                            {type === 'invitation' && (
                                <div className="templates__section__counter-char">
                                    <span className={alternative?.length > invitLimit - 20 ? ' red' : ''}>
                                        {alternative?.length || 0} / {invitLimit}
                                    </span>
                                </div>
                            )}
                            <div className="templates__section__message">
                                <Input
                                    size="large"
                                    type="textarea"
                                    maxlength={type === 'invitation' ? `${invitLimit}` : undefined}
                                    ref={alternativeRef}
                                    onChange={(e) => setAlternative(e.target.value)}
                                    placeholder={tr('Type in a message template content')}
                                    value={alternative}
                                />
                            </div>
                            {templateAttachments(alternativeRef, alternative, setAlternative)}
                        </div>
                    )}
                </div>
                <div className="templates__footer">
                    {template && (template?.title !== title || template?.content !== content) && (
                        <Button
                            type="transparent"
                            isBordered
                            icon="undo"
                            label={tr('Discard current changes')}
                            onClick={reset}
                        />
                    )}
                    <Button
                        disabled={
                            (!title && !content) ||
                            (type === template?.type &&
                                template?.title === title &&
                                template?.content === content &&
                                template?.alternative === alternative)
                        }
                        label={tr('Save')}
                        onClick={() =>
                            template
                                ? actions.update(template.id, title, content, alternative, type)
                                : actions.add(title, content, alternative, type)
                        }
                    />
                </div>
            </div>
        </div>
    );
}
export default Templates;
