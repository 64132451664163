/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import moment from 'moment';
import Linkify from 'linkify-react';
import utils from './utils';
import { tr } from './locale';
import Tooltip from '../components/Tooltip';
import Icon from '../components/Icon';

function date(message) {
    return moment(message.createdAt).format('LLL');
}

function canSendMessage(user, lnUser) {
    return lnUser?.is_connection || (lnUser?.last_message && !lnUser?.is_sponsor) || lnUser?.lead.is_open_profile;
}

function lastMessageText(connection) {
    return connection.last_message?.last_message_text || connection.last_message?.last_message_attachment_name;
}

function lastMessageIsUser(connection) {
    return !(connection?.lead.linkedin_id === connection.last_message?.last_message_from_id);
}

function userRelation(lnUser, level) {
    if (lnUser?.is_connection && !lnUser?.is_sponsor) {
        return <strong title={tr('Connection')}>{tr('Connection')}</strong>;
    }
    let levelLabel = '';
    if (level && lnUser.degree && lnUser.degree > 1) {
        if (lnUser.degree === 2) {
            levelLabel = ' - 2nd';
        } else if (lnUser.degree === 3) {
            levelLabel = ' - 3rd';
        } else {
            levelLabel = ` - ${lnUser.degree}th`;
        }
    }
    console.log('lnUser.degree', level, levelLabel);
    if (!lnUser?.is_connection && lnUser?.invitation_id && lnUser?.invitation_type === 'SENT') {
        return <strong title={tr('Connection request sent')}>{tr('Request sent') + levelLabel}</strong>;
    }
    if (!lnUser?.is_connection && lnUser?.invitation_id && lnUser?.invitation_type === 'PENDING') {
        return <strong title={tr('Connection request received')}>{tr('Request received') + levelLabel}</strong>;
    }
    if (!lnUser?.is_connection && lnUser?.invitation_type === 'REFUSED') {
        return <strong title={tr('Connection refused')}>{tr('Connection refused') + levelLabel}</strong>;
    }
    if (!lnUser?.is_connection && lnUser?.invitation_type === 'IGNORED') {
        return <strong title={tr('Connection request ignored')}>{tr('Request ignored') + levelLabel}</strong>;
    }
    if (!lnUser?.is_connection && lnUser?.invitation_type === 'WITHDRAWN') {
        return (
            <strong title={tr("You've withdrawn connection request")}>
                {tr("You've withdrawn connection request")}
            </strong>
        );
    }
    if (lnUser?.is_connection && lnUser?.is_sponsor) {
        return <strong title={tr('Sponsor Connection')}>{tr('Sponsor Connection')}</strong>;
    }
    if (!lnUser?.isconnection && lnUser?.is_sponsor) {
        return <strong title={tr('Sponsor')}>{tr('Sponsor')}</strong>;
    }
    return <strong title={tr('Contact')}>{tr('Contact') + levelLabel}</strong>;
}

function userListTypeClass(lnUser) {
    if (lnUser.is_sponsor) {
        return 'sponsor-item';
    }
    if (lnUser?.is_lead) {
        if (lnUser?.is_connection) {
            return 'lead-relation-item';
        }
        return 'lead-contact-item';
    }
    if (lnUser?.is_connection) {
        return 'relation-item';
    }
    return 'contact-item';
}
function avatar(message) {
    if (message?.from && message.from['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile?.picture) {
        const pict =
            message.from['com.linkedin.voyager.messaging.MessagingMember'].miniProfile.picture[
                'com.linkedin.common.VectorImage'
            ];
        if (pict) {
            return pict.rootUrl + pict.artifacts[0].fileIdentifyingUrlPathSegment;
        }
    }
    return '';
}

function isSponsored(message) {
    if (
        message?.eventContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.customContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.customContent[
            'com.linkedin.voyager.messaging.event.message.spinmail.SpInmailContent'
        ]?.advertiserLabel
    ) {
        return true;
    }
    return '';
}

function author(message, lnUser) {
    if (message?.from && message.from['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile) {
        const profile = message.from['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile;
        if (isSponsored(message) && !profile.lastName && lnUser) {
            return `${lnUser.lead.firstname} ${lnUser.lead.lastname}`;
        }
        return `${profile.firstName} ${profile.lastName}`;
    }
    if (isSponsored(message)) {
        return tr('Sponsor user');
    }
    return 'No name';
}

function messageUrn(message, user) {
    return `urn:li:msg_message:(urn:li:fsd_profile:${user.linkedin_id},${
        message.dashEntityUrn?.split('fsd_message:')[1]
    })`;
}

function authorPublicId(message) {
    if (message?.from && message.from['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile) {
        const profile = message.from['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile;
        return profile.publicIdentifier;
    }
    return null;
}

function sharedPost(message) {
    if (
        message?.eventContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.feedUpdate
    ) {
        const feed = message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.feedUpdate;
        const { updateMetadata, content, commentary } = feed;
        const article = content ? content['com.linkedin.voyager.feed.render.ArticleComponent'] : null;
        const image = article?.largeImage?.attributes;
        return (
            <a
                target="_blank"
                href={`https://www.linkedin.com/feed/update/${updateMetadata.shareUrn}/`}
                rel="noreferrer"
            >
                {image && (
                    <img
                        src={
                            image[0].vectorImage.rootUrl +
                            image[0].vectorImage.artifacts[0].fileIdentifyingUrlPathSegment
                        }
                        alt={article?.title?.text}
                    />
                )}
                <div>{commentary?.text?.text}</div>
            </a>
        );
    }
    return '';
}

function createMessage(lastMessage) {
    return {
        createdAt: lastMessage.last_message_at,
        reactionSummaries: [],
        dashEntityUrn: `urn:li:fsd_message:${lastMessage.last_message_id}`,
        eventContent: {
            'com.linkedin.voyager.messaging.event.MessageEvent': {
                customContent: {
                    'com.linkedin.voyager.messaging.event.message.spinmail.SpInmailContent': {
                        advertiserLabel: 'Sponsored',
                        body: lastMessage.last_message_html,
                        subContent: {
                            'com.linkedin.voyager.messaging.event.message.spinmail.SpInmailStandardSubContent': {
                                action: lastMessage.last_message_action_link,
                                actionText: lastMessage.last_message_action_text,
                            },
                        },
                    },
                },
            },
            body: '',
            attributedBody: {
                text: lastMessage.last_message_text,
            },
        },
    };
}

function text(message) {
    if (
        message?.eventContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.attributedBody
    ) {
        const mess = message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.attributedBody;
        return (
            <Linkify options={{ target: '_blank', rel: 'noopener', defaultProtocol: 'https' }}>
                {utils.nl2br(mess.text)}
            </Linkify>
        );
    }
    if (message?.eventContent && message.eventContent['com.linkedin.voyager.messaging.event.ParticipantChangeEvent']) {
        const addedParticipants =
            message.eventContent['com.linkedin.voyager.messaging.event.ParticipantChangeEvent']?.addedParticipants;
        const removedParticipants =
            message.eventContent['com.linkedin.voyager.messaging.event.ParticipantChangeEvent']?.removedParticipants;

        return (
            <>
                {addedParticipants.length > 0 && (
                    <div>
                        {tr('Added participants:')}&nbsp;
                        {addedParticipants
                            .map(
                                (participant) =>
                                    `${participant['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile?.firstName} ${participant['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile?.lastName}`
                            )
                            .join(', ')}
                    </div>
                )}
                {removedParticipants.length > 0 && (
                    <div>
                        {tr('Removed participants:')}&nbsp;
                        {removedParticipants
                            .map(
                                (participant) =>
                                    `${participant['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile?.firstName} ${participant['com.linkedin.voyager.messaging.MessagingMember']?.miniProfile?.lastName}`
                            )
                            .join(', ')}
                    </div>
                )}
            </>
        );
    }

    if (
        message?.eventContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.customContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.customContent[
            'com.linkedin.voyager.messaging.event.message.spinmail.SpInmailContent'
        ]?.body
    ) {
        const inMail =
            message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.customContent[
                'com.linkedin.voyager.messaging.event.message.spinmail.SpInmailContent'
            ];
        const mess = inMail?.body;
        // eslint-disable-next-line react/no-danger
        let action = null;
        if (
            inMail?.subContent &&
            inMail?.subContent['com.linkedin.voyager.messaging.event.message.spinmail.SpInmailStandardSubContent']
        ) {
            const subcontent =
                inMail?.subContent['com.linkedin.voyager.messaging.event.message.spinmail.SpInmailStandardSubContent'];
            action = (
                <div>
                    <a href={subcontent.action} target="_blank" rel="noreferrer">
                        {subcontent.actionText}
                    </a>
                </div>
            );
        }
        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: mess }} />
                {action}
            </>
        );
    }
    return '';
}

function read(message, conversation, messages) {
    if (conversation && messages?.elements) {
        const lastMess = messages?.elements.find(
            (mess) => conversation?.participant?.lead.linkedin_public_id !== authorPublicId(mess)
        );
        if (
            lastMess &&
            conversation?.participant &&
            moment(lastMess.createdAt).unix() <= moment(conversation.seen_at).unix() &&
            lastMess === message
        ) {
            return (
                <Tooltip direction="nw" text={tr('Read - ') + moment(conversation.seen_at).format('LLL')}>
                    <img
                        className="chat__message__read__avatar"
                        src={conversation?.participant?.lead.picture}
                        alt={`${conversation?.participant?.lead.firstname} ${conversation.participant.lead.lastname}`}
                    />
                </Tooltip>
            );
        }
    }
    return '';
}

function reactions(message, participant, onClick) {
    if (message?.reactionSummaries?.length > 0) {
        return message?.reactionSummaries.map((reaction) => (
            <div key={reaction.emoji} className={`reaction ${reaction.viewerReacted ? 'me' : ''}`}>
                <Tooltip
                    text={
                        reaction.viewerReacted ? tr('Me') : `${participant.lead.firstname} ${participant.lead.lastname}`
                    }
                >
                    <div onClick={() => onClick(reaction.emoji)} role="button" tabIndex="-1" onKeyDown={() => null}>
                        {reaction.emoji}
                    </div>
                </Tooltip>
            </div>
        ));
    }
    return '';
}

function isImage(mediaType) {
    return mediaType === 'image/jpeg' || mediaType === 'image/png';
}

function attachmentType(mediaType) {
    if (mediaType === 'application/pdf') {
        return 'pdf';
    }
    return 'file';
}

function attachments(message) {
    if (
        message?.eventContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.attachments
    ) {
        const attachs = message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.attachments;
        if (attachs) {
            return attachs.map((attachment) => (
                <React.Fragment key={attachment.id}>
                    {isImage(attachment.mediaType) && (
                        <img style={{ maxHeight: '20rem' }} src={attachment.reference.string} alt={attachment.name} />
                    )}
                    {!isImage(attachment.mediaType) && (
                        <a
                            className="attachment"
                            key={attachment.id}
                            href={attachment.reference.string}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div
                                className={`file ${attachmentType(attachment.mediaType) === 'pdf' ? 'file--pdf' : ''}`}
                            >
                                <Icon
                                    fill
                                    size={30}
                                    icon={`${attachmentType(attachment.mediaType) === 'pdf' ? 'file-pdf' : 'file'}`}
                                />
                                <div className="file__info">
                                    <div className="file__name">{attachment.name}</div>
                                    <div className="file__size">
                                        {attachment.byteSize && <div>{Math.round(attachment.byteSize / 1000)}KB</div>}
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                </React.Fragment>
            ));
        }
        return null;
    }
    if (
        message?.eventContent &&
        message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.mediaAttachments
    ) {
        const medias = message.eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.mediaAttachments;
        if (medias) {
            return medias.map((attachment) => {
                if (attachment.audioMetadata) {
                    return (
                        <React.Fragment key={attachment.audioMetadata?.urn}>
                            {attachment.mediaType === 'AUDIO' && (
                                <audio controls preload="auto" src={attachment.audioMetadata.url} type="audio/mpeg" />
                            )}
                        </React.Fragment>
                    );
                }
                if (
                    attachment.videoPlayMetadata?.progressiveStreams[0]?.streamingLocations &&
                    attachment.mediaType === 'VIDEO'
                ) {
                    return (
                        <React.Fragment key={attachment.videoPlayMetadata?.entityUrn}>
                            <video
                                controls
                                preload="auto"
                                src={attachment.videoPlayMetadata?.progressiveStreams[0]?.streamingLocations[0].url}
                                type={attachment.videoPlayMetadata?.progressiveStreams[0]?.mediaType}
                            />
                        </React.Fragment>
                    );
                }

                return null;
            });
        }
        return null;
    }
    return null;
}

export default {
    userListTypeClass,
    lastMessageIsUser,
    canSendMessage,
    lastMessageText,
    createMessage,
    isSponsored,
    sharedPost,
    messageUrn,
    read,
    reactions,
    userRelation,
    date,
    avatar,
    attachments,
    author,
    authorPublicId,
    text,
};
