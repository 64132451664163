import { atom } from 'recoil';

export const templatesState = atom({
    key: 'templatesState',
    default: [],
});

export const templateState = atom({
    key: 'templateState',
    default: null,
});

export const loadingState = atom({
    key: 'templatesLoadingState',
    default: false,
});

export const savingTemplateState = atom({
    key: 'savingTemplateState',
    default: false,
});
