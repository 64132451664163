import { tr } from '../../common/locale';
import Switch from '../../components/Switch';

function Settings() {
    return (
        <div className="content__body automation-mode">
            <div className="settings__header">
                <h2 className="title">{tr('Automations modes')}</h2>
            </div>
            <div className="settings__content">
                <p className="mb--30">{tr('Cloud based automations will be available soon.')}</p>

                <Switch id="automationMode" disabled text={tr('Switch from cloud-based to browser mode')} />
            </div>
        </div>
    );
}
export default Settings;
