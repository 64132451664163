import { useRecoilValue, useRecoilState } from 'recoil';
import { useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import Switch from '../../components/Switch';
import Icon from '../../components/Icon';
import { userState, quotasState } from '../../_states/user.states';
import { tr } from '../../common/locale';
import useUserActions from '../../_actions/user.actions';
import 'rc-slider/assets/index.css';

function Schedule() {
    const user = useRecoilValue(userState);
    const [quotas, setQuotas] = useRecoilState(quotasState);
    const actions = useUserActions();

    const saveQuotas = () => {
        actions.updateQuotas(true);
    };
    const debouncedSaveQuotas = useCallback(debounce(saveQuotas, 300), []);
    useEffect(() => {
        actions.refreshMe();
    }, []);
    useEffect(() => {
        if (user) {
            setQuotas(user.quotas);
        }
    }, [user]);

    const defaultTz = moment.tz.guess();

    const toggleDay = (day) => {
        if (quotas.schedule_days.indexOf(day) === -1) {
            setQuotas({ ...quotas, schedule_days: [...quotas.schedule_days, day] });
            debouncedSaveQuotas();
        } else {
            const result = [...quotas.schedule_days];
            result.splice(quotas.schedule_days.indexOf(day), 1);
            setQuotas({
                ...quotas,
                schedule_days: result,
            });
            debouncedSaveQuotas();
        }
    };

    const generateHours = (startIndex) => {
        const result = [];
        new Array(24).fill().forEach((acc, index) => {
            if (!startIndex || index >= startIndex) {
                const val = moment({ hour: index }).format('h:mm A');
                const val30 = moment({ hour: index, minute: 30 }).format('h:mm A');
                result.push({
                    value: val,
                    name: val,
                    label: val,
                });
                result.push({
                    value: val30,
                    name: val30,
                    label: val30,
                });
            }
        });
        return result;
    };
    const endHour = {
        value: moment({
            hour: quotas.schedule_end_hour,
            minute: quotas.schedule_end_min,
        }).format('h:mm A'),
        name: moment({
            hour: quotas.schedule_end_hour,
            minute: quotas.schedule_end_min,
        }).format('h:mm A'),
        label: moment({
            hour: quotas.schedule_end_hour,
            minute: quotas.schedule_end_min,
        }).format('h:mm A'),
    };
    const startHour = {
        value: moment({
            hour: quotas.schedule_start_hour,
            minute: quotas.schedule_start_min,
        }).format('h:mm A'),
        name: moment({
            hour: quotas.schedule_start_hour,
            minute: quotas.schedule_start_min,
        }).format('h:mm A'),
        label: moment({
            hour: quotas.schedule_start_hour,
            minute: quotas.schedule_start_min,
        }).format('h:mm A'),
    };

    return (
        <div className="content__body timezone">
            <div className="settings__header">
                <h2 className="title">{tr('Scheduler (for automations)')}</h2>
            </div>
            <div className="settings__content">
                <div className="timezone__picker">
                    <h4>{tr('Choose your timezone')}</h4>
                    {quotas.id && (
                        <Select
                            closeMenuOnSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selected) => {
                                setQuotas({ ...quotas, schedule_timezone: selected.value });
                                debouncedSaveQuotas();
                            }}
                            defaultValue={{
                                value: quotas.schedule_timezone || defaultTz,
                                name: quotas.schedule_timezone || defaultTz,
                                label: quotas.schedule_timezone || defaultTz,
                            }}
                            options={moment.tz.names().map((name) => ({
                                value: name,
                                name,
                                label: name,
                            }))}
                        />
                    )}
                </div>
                <div className="timezone__configure">
                    <h4>{tr('Choose the days you want to process the sequence')}</h4>
                    <div className="timezone__week-days">
                        <ul>
                            <li>
                                <Switch
                                    text={tr('Monday')}
                                    id="monday"
                                    checked={quotas?.schedule_days?.indexOf(0) !== -1}
                                    onChange={() => toggleDay(0)}
                                />
                            </li>
                            <li>
                                <Switch
                                    text={tr('Tuesday')}
                                    id="tuesday"
                                    checked={quotas?.schedule_days?.indexOf(1) !== -1}
                                    onChange={() => toggleDay(1)}
                                />
                            </li>
                            <li>
                                <Switch
                                    text={tr('Wednesday')}
                                    id="wednesday"
                                    checked={quotas?.schedule_days?.indexOf(2) !== -1}
                                    onChange={() => toggleDay(2)}
                                />
                            </li>
                            <li>
                                <Switch
                                    text={tr('Thursday')}
                                    id="thursday"
                                    checked={quotas?.schedule_days?.indexOf(3) !== -1}
                                    onChange={() => toggleDay(3)}
                                />
                            </li>
                            <li>
                                <Switch
                                    text={tr('Friday')}
                                    id="friday"
                                    checked={quotas?.schedule_days?.indexOf(4) !== -1}
                                    onChange={() => toggleDay(4)}
                                />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Switch
                                    text={tr('Saturday')}
                                    id="saturday"
                                    checked={quotas?.schedule_days?.indexOf(5) !== -1}
                                    onChange={() => toggleDay(5)}
                                />
                            </li>
                            <li>
                                <Switch
                                    text={tr('Sunday')}
                                    id="sunday"
                                    checked={quotas?.schedule_days?.indexOf(6) !== -1}
                                    onChange={() => toggleDay(6)}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="timezone__hours">
                    <div>{tr('From')}</div>
                    {quotas.id && (
                        <Select
                            closeMenuOnSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selected) => {
                                const hour = moment(selected.value, ['h:m a', 'H:m']).format('H');
                                const min = moment(selected.value, ['h:m a', 'H:m']).format('m');
                                setQuotas({
                                    ...quotas,
                                    schedule_start_hour: parseInt(hour, 10),
                                    schedule_start_min: parseInt(min, 10),
                                });
                                debouncedSaveQuotas();
                            }}
                            defaultValue={startHour}
                            options={generateHours()}
                        />
                    )}
                    <div>{tr('To')}</div>
                    {quotas.id && (
                        <Select
                            closeMenuOnSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selected) => {
                                const hour = moment(selected.value, ['h:m a', 'H:m']).format('H');
                                const min = moment(selected.value, ['h:m a', 'H:m']).format('m');
                                setQuotas({
                                    ...quotas,
                                    schedule_end_hour: parseInt(hour, 10),
                                    schedule_end_min: parseInt(min, 10),
                                });
                                debouncedSaveQuotas();
                            }}
                            defaultValue={endHour}
                            options={generateHours(
                                quotas?.schedule_start_min
                                    ? quotas?.schedule_start_hour + 1
                                    : quotas?.schedule_start_hour
                            )}
                        />
                    )}
                </div>
                <div>
                    <NavLink className="btn btn--primary btn--has-icon" to="/campaigns">
                        <Icon size={26} icon="play-square" />
                        <span className="btn__label">{tr('Access to automations')}</span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}
export default Schedule;
