import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Select, { components } from 'react-select';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import Flash from '../../components/Flash';
import Label from '../../components/Label';
import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';
import Labels, { Colors } from '../lnuser/LabelsList';
import useLnUsersActions from '../../_actions/lnusers.actions';
import useBoardAction from '../../_actions/board.actions';
import messageUtils from '../../common/messageUtils';
import { boardState, boardsState } from '../../_states/lnusers.states';
import { labelsState } from '../../_states/label.states';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

function BulkMoveLaveAction({ addLabel, moveBoard, boardId, laneId, selectedUsers, closeCb }) {
    const lnUsersActions = useLnUsersActions();
    const boardActions = useBoardAction();
    const board = useRecoilValue(boardState);
    const boards = useRecoilValue(boardsState);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState(board);
    const labels = useRecoilValue(labelsState);
    const [usersToProcess, setUsersToProcess] = useState(selectedUsers);
    const [filteredUsersToProcess, setFilteredUsersToProcess] = useState(selectedUsers);
    const [selectedStep, setSelectedStep] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filterLabel, setFilterLabel] = useState(null);
    const [countMembers, setCountMembers] = useState(10);

    useEffect(() => {
        if (selectedFilter?.id === 'is_connection') {
            setFilteredUsersToProcess(usersToProcess?.filter((user) => user.is_connection));
        } else if (selectedFilter?.id === 'is_invited') {
            setFilteredUsersToProcess(
                usersToProcess?.filter(
                    (user) =>
                        user.invitation_id && (user.invitation_type === 'PENDING' || user.invitation_type === 'SENT')
                )
            );
        } else if (selectedFilter?.id === 'has_answered') {
            setFilteredUsersToProcess(
                usersToProcess?.filter((user) => user.last_message && !messageUtils.lastMessageIsUser(user))
            );
        } else if (selectedFilter?.id === 'has_email') {
            setFilteredUsersToProcess(usersToProcess?.filter((user) => !!user.lead.email));
        } else if (selectedFilter?.id === 'has_phone') {
            setFilteredUsersToProcess(usersToProcess?.filter((user) => !!user.lead.phone));
        } else if (selectedFilter?.id === 'is_open_profile') {
            setFilteredUsersToProcess(usersToProcess?.filter((user) => user.lead.is_open_profile));
        } else if (selectedFilter?.id === 'has_conversation') {
            setFilteredUsersToProcess(usersToProcess?.filter((user) => user.last_message));
        } else if (selectedFilter?.id === 'has_label' && filterLabel) {
            setFilteredUsersToProcess(
                usersToProcess?.filter((user) => user.labels?.find((label) => filterLabel.value === label.id))
            );
        } else if (selectedFilter?.id === 'count') {
            setFilteredUsersToProcess(usersToProcess?.slice(0, countMembers));
        } else {
            setFilteredUsersToProcess(usersToProcess);
        }
    }, [selectedFilter, filterLabel, countMembers]);
    const filters = [
        {
            name: tr('All members of the column'),
            id: 'all',
        },
        {
            name: tr('Only a limited amount of members'),
            id: 'count',
        },
        {
            name: tr('Is a connection'),
            id: 'is_connection',
        },
        {
            name: tr('Is invited'),
            id: 'is_invited',
        },
        {
            name: tr('Has an email'),
            id: 'has_email',
        },
        {
            name: tr('Has a phone'),
            id: 'has_phone',
        },
        {
            name: tr('Has a conversation'),
            id: 'has_conversation',
        },
        {
            name: tr('Has answered'),
            id: 'has_answered',
        },
        {
            name: tr('Is an open profile'),
            id: 'is_open_profile',
        },
        {
            name: tr('Has a label'),
            id: 'has_label',
        },
    ];
    const onChangeLabels = (selected) => {
        setFilterLabel(selected);
    };
    const bulkMoveLane = () => {
        if (addLabel) {
            lnUsersActions.addLabelsLane(boardId, selectedLabels, filteredUsersToProcess);
        } else {
            lnUsersActions.moveLane(boardId, selectedBoard.id, filteredUsersToProcess, selectedStep);
        }
    };

    useEffect(() => {
        if (boardId && laneId && !usersToProcess) {
            boardActions.getUsers(boardId, laneId).then((users) => {
                setUsersToProcess(users);
                setFilteredUsersToProcess(users);
            });
        }
    }, []);
    const count = filteredUsersToProcess?.length || 0;
    const currentStep = board.lanes?.find((lane) => lane.id === laneId);
    return (
        <Modal
            className="modal--move-to-board"
            closeCb={closeCb}
            defaultIsOpen
            overflow
            disabled={addLabel ? selectedLabels.length === 0 : !selectedStep}
            title={
                moveBoard
                    ? tr('Move members from {{title}} column to another pipeline', { title: currentStep?.title })
                    : tr('Move members from {{title}} column', { title: currentStep?.title })
            }
            validateCb={bulkMoveLane}
            labelValidate={addLabel ? tr('Add') : tr('Move')}
        >
            <Flash color="blue">
                <p>{tr('{{count}} members selected', { count })}</p>
            </Flash>
            <div className="modal--move-to-board__section">
                <p>{tr('You can apply a filter to select the users you want to move:')}</p>
                <div>
                    <Dropdown
                        iconRight
                        btnFaceIconSize={16}
                        btnFace="secondary"
                        btnClassName="btn--bordered mb--10"
                        label={selectedFilter?.name || tr('All users of the column')}
                        onClick={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown.Menu direction="s">
                            {filters.map((element) => (
                                <Dropdown.ButtonItem
                                    key={element?.id}
                                    btnLabel={element.name}
                                    onClick={() => {
                                        setSelectedFilter(element);
                                    }}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {selectedFilter?.id === 'has_label' && (
                        <Select
                            closeMenuOnSelect
                            placeholder={tr('Select label')}
                            className="board__label"
                            classNamePrefix="react-select"
                            onChange={onChangeLabels}
                            components={{ Option: LabelOption }}
                            options={labels?.map((label) => ({
                                value: label.id,
                                name: label.name,
                                color: label.color,
                                label: label.name,
                            }))}
                            styles={{
                                multiValue: (styles, { data }) => ({
                                    ...styles,
                                    backgroundColor: Colors[data.color].light,
                                }),
                            }}
                        />
                    )}
                    {selectedFilter?.id === 'count' && (
                        <>
                            <div className="mb--10">{tr('Number of members to move:')}</div>
                            <Input
                                className="modal--move-to-board__count"
                                value={countMembers}
                                onChange={(e) => setCountMembers(e.target.value)}
                                placeholder={tr('Number of members')}
                            />
                        </>
                    )}
                </div>
            </div>
            {addLabel && (
                <div className="modal--move-to-board__section">
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onChange={(selected) => {
                            setSelectedLabels(selected.map((item) => item.value));
                        }}
                        components={{ Option: LabelOption }}
                        options={labels?.map((label) => ({
                            value: label.id,
                            name: label.name,
                            color: label.color,
                            label: label.name,
                        }))}
                        styles={{
                            multiValue: (styles, { data }) => ({
                                ...styles,
                                backgroundColor: Colors[data.color].light,
                            }),
                        }}
                    />
                    <Labels disableSelect />
                </div>
            )}
            {!addLabel && (
                <div className="modal--move-to-board__section">
                    <p>{moveBoard ? tr('Target Pipeline') : tr('Target Column')}</p>
                    <div className="btn--group">
                        {moveBoard && (
                            <Dropdown
                                iconRight
                                btnFaceIconSize={16}
                                btnFace="secondary-dark"
                                btnClassName="btn--group-item"
                                label={selectedBoard?.name || tr('Select a Pipeline')}
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                {boards?.length > 0 && (
                                    <Dropdown.Menu direction="s">
                                        {boards
                                            ?.filter((elem) => !elem.campaign)
                                            .map((element) => (
                                                <Dropdown.ButtonItem
                                                    key={element?.id}
                                                    btnLabel={element.name || tr('+ Add to Pipeline')}
                                                    onClick={() => {
                                                        setSelectedBoard(element);
                                                        setSelectedStep(null);
                                                    }}
                                                />
                                            ))}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        )}
                        <Dropdown
                            iconRight
                            btnFaceIconSize={16}
                            btnFace={moveBoard ? 'secondary-dark' : 'secondary'}
                            btnClassName={moveBoard ? 'btn--group-item' : 'btn--bordered'}
                            label={selectedStep?.title || tr('Choose column')}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            <Dropdown.Menu direction="s">
                                {selectedBoard?.lanes
                                    ?.filter((lane) => lane.id !== laneId)
                                    .map((element) => (
                                        <Dropdown.ButtonItem
                                            key={element.id}
                                            btnLabel={element.title}
                                            onClick={() => {
                                                setSelectedStep(element);
                                            }}
                                        />
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            )}
        </Modal>
    );
}
export default BulkMoveLaveAction;
