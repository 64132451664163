import { useRef, useState } from 'react';
import { components } from 'react-select';
import { useRecoilValue } from 'recoil';
import Button from '../../components/Button';
import AsyncSelect from '../../components/AsyncSelect';
import Avatar from '../../components/Avatar';
import ConfirmButton from '../../components/ConfirmButton';
import Modal from '../../components/Modal';
import Flash from '../../components/Flash';
import useCampaignActions from '../../_actions/campaign.actions';
import { removedMembersState } from '../../_states/campaign.states';
import { tr } from '../../common/locale';

const Option = (props) => {
    const { data } = props;
    // const leadLabel = data.is_lead ? `${tr('Lead')} ` : '';
    return (
        <components.Option {...props}>
            <div className="board__add-member__avatar">
                <Avatar className="board__add-member__avatar__img" src={data.picture} alt={data.name} />
            </div>
            <div className="board__add-member__info">
                <div className="board__add-member__name">
                    <span>{data.name}</span>
                </div>
            </div>
        </components.Option>
    );
};

const CampaignExcludeMembers = ({ campaign }) => {
    const modalRef = useRef();
    const actions = useCampaignActions();
    const removedMembers = useRecoilValue(removedMembersState);
    const [isOpen, setIsOpen] = useState(false);
    const loadUsers = (newValue, callback) => {
        setIsOpen(true);
        actions.autocomplete(campaign.recipient_from_label_id, campaign.recipient_from_list_id, newValue, callback);
    };
    const onChange = (selected) => {
        if (selected) {
            console.log('selected', selected);
            actions.removeMember(campaign, selected.value);
        }
    };

    console.log('removedMembers', removedMembers);

    return (
        <div>
            <Modal
                overflow
                ref={modalRef}
                title={tr('Exclude members from the campaign')}
                className="exclude-members"
                labelValidate={tr('Close')}
                mainButtonType="white"
                mainButtonBordered
            >
                <Flash color="blue" className="mb--20">
                    {tr(
                        'You can also exclude a member by removing them from the CRM Pipeline column of your campaign.'
                    )}
                </Flash>
                <p className="mb--10 bold">{tr('Select members to exclude from the campaign')}</p>
                <AsyncSelect
                    blurInputOnSelect
                    isClearable
                    components={{ Option }}
                    onFocus={() => setIsOpen(true)}
                    loadOptions={loadUsers}
                    onChange={onChange}
                    value={{ label: tr('Type in to search for a member') }}
                    placeholder={tr('Type in to search for a member')}
                    onBlur={() => setIsOpen(false)}
                    menuIsOpen={isOpen}
                />

                <ul className="exclude-members__item mt--20">
                    {removedMembers?.map((member) => (
                        <li key={member.id}>
                            <div className="flex gap--4">
                                <span className="bold">
                                    {member.lead.firstname} {member.lead.lastname}
                                </span>
                                <span>{tr('is excluded from your campaign')}</span>
                            </div>
                            <ConfirmButton
                                label={tr('Undo / Include')}
                                type="green"
                                size="mini"
                                icon="undo"
                                iconSize={16}
                                confirmLabel={tr('Do you confirm you want include this member in your campaign?')}
                                onConfirm={() => actions.notRemoveMember(campaign, member.lead.id)}
                            />
                        </li>
                    ))}
                </ul>
            </Modal>
            <Button
                className="ml--30"
                type="link-red"
                icon="close-circle"
                label={tr('Exclude members')}
                onClick={() => {
                    actions.getRemovedMembers(campaign);
                    modalRef.current.open();
                }}
            />
        </div>
    );
};

export default CampaignExcludeMembers;
