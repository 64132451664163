import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';
import { string } from '@recoiljs/refine';

export const syncState = atom({
    key: 'syncConnectionsState',
    default: null,
});

export const notesState = atom({
    key: 'notesLnUsersState',
    default: null,
});

export const loadingState = atom({
    key: 'loadingConnectionsState',
    default: true,
});

export const selectedBoardIdState = atom({
    key: 'selectedBoardIdState',
    default: localStorage.getItem('selectedBoard') ? parseInt(localStorage.getItem('selectedBoard'), 10) : null,
});

export const initBoardLanesState = atom({
    key: 'initBoardLanesState',
    default: false,
});

export const lastSelectState = atom({
    key: 'lastSelectState',
    default: -1,
});

export const boardState = atom({
    key: 'boardConnectionsState',
    default: null,
});

export const boardsState = atom({
    key: 'boardsConnectionsState',
    default: null,
});

export const boardsLoadingState = atom({
    key: 'boardsLoadingState',
    default: false,
});

export const connectionsState = atom({
    key: 'connectionsState',
    default: null,
});

export const filterNameState = atom({
    key: 'filterNameConnectionsState',
    default: '',
});

export const filterCompanyState = atom({
    key: 'filterCompanyConnectionsState',
    default: '',
});

export const filterJobState = atom({
    key: 'filterJobConnectionsState',
    default: '',
});

export const filterLocationState = atom({
    key: 'filterLocationConnectionsState',
    default: '',
});

export const filterHeadlineState = atom({
    key: 'filterHeadlineConnectionsState',
    default: '',
});

export const filterLabelsState = atom({
    key: 'filterLabelsConnectionState',
    default: [],
});

export const filterNoLabelState = atom({
    key: 'filteNoLabelState',
    default: false,
});

export const filterConnectionsState = atom({
    key: 'filterConnectionsState',
    default: 'conversations',
    effects: [syncEffect({ refine: string() })],
});

export const filterInboxState = atom({
    key: 'filterInboxState',
    default: 'all',
});

export const boardFilterState = atom({
    key: 'boardFilterConnetionsState',
    default: '',
});

export const selectedUsersState = atom({
    key: 'selectedUsersState',
    default: [],
});

export const bulkSendProgressState = atom({
    key: 'bulkSendProgressState',
    default: undefined,
});

export const bulkRemindProgressState = atom({
    key: 'bulkRemindProgressState',
    default: undefined,
});

export const bulkInviteProgressState = atom({
    key: 'bulkInviteProgressState',
    default: undefined,
});

export const bulkAcceptInviteProgressState = atom({
    key: 'bulkAcceptInviteProgressState',
    default: undefined,
});

export const bulkProcessLeadsProgressState = atom({
    key: 'bulkProcessLeadsProgressState',
    default: undefined,
});

export const bulkMoveProgressState = atom({
    key: 'bulkMoveProgressState',
    default: undefined,
});

export const csvExportState = atom({
    key: 'csvExportState',
    default: false,
});

export const noteBoardState = atom({
    key: 'noteBoardState',
    default: null,
});

export const csvExportBoardState = atom({
    key: 'csvExportBoardState',
    default: false,
});

export const bulkSyncProgressState = atom({
    key: 'bulkSyncProgressState',
    default: undefined,
});

export const bulkLabelProgressState = atom({
    key: 'bulkLabelProgressState',
    default: undefined,
});

export const bulkBoardProgressState = atom({
    key: 'bulkBoardProgressState',
    default: undefined,
});

export const enrichLoadingState = atom({
    key: 'enrichLoadingState',
    default: false,
});

export const moveLaneLoadingState = atom({
    key: 'moveLaneLoadingState',
    default: false,
});

export const bulkHideProgressState = atom({
    key: 'bulkHideProgressState',
    default: undefined,
});

export const bulkArchiveProgressState = atom({
    key: 'bulkArchiveProgressState',
    default: undefined,
});

export const bulkUnreadProgressState = atom({
    key: 'bulkUnreadProgressState',
    default: undefined,
});

export const bulkDisconnectProgressState = atom({
    key: 'bulkDisconnectProgressState',
    default: undefined,
});
