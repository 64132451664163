import { useState, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import useLinkdInActions from '../../_actions/linkedin.actions';
import { bulkSendProgressState } from '../../_states/lnusers.states';
import useBoardAction from '../../_actions/board.actions';

function BulkSyncAction({ boardId, laneId, selectedUsers, closeCb, actionCb }) {
    const boardActions = useBoardAction();
    const modalRef = useRef();
    const [usersToProcess, setUsersToProcess] = useState(selectedUsers);
    const linkedInActions = useLinkdInActions();

    const bulkSendProgress = useRecoilValue(bulkSendProgressState);
    const bulkSync = () => {
        if (actionCb) {
            actionCb();
        }
        linkedInActions.bulkSync(usersToProcess);
    };
    useEffect(() => {
        if (usersToProcess) {
            bulkSync();
        }
    }, [usersToProcess]);

    useEffect(() => {
        if (modalRef) {
            modalRef.current.setLoading(true);
        }
    }, []);

    useEffect(() => {
        if (boardId && laneId && !usersToProcess) {
            boardActions.getUsers(boardId, laneId).then((users) => setUsersToProcess(users));
        }
    }, []);

    return (
        <Modal
            defaultIsOpen
            ref={modalRef}
            closeCb={closeCb}
            overflow
            className="bulk-actions-modal__message"
            title={tr('Synchronizing selected members')}
            labelValidate={tr('Synchronize')}
        >
            {usersToProcess === undefined && bulkSendProgress === undefined && <div>{tr('Loading members')}</div>}
        </Modal>
    );
}
export default BulkSyncAction;
