import { useRecoilValue, useRecoilState } from 'recoil';
import { useEffect, useCallback } from 'react';
import Slider from 'rc-slider';
import debounce from 'lodash.debounce';
import { userState, savingQuotasState, quotasState } from '../../_states/user.states';
import { tr } from '../../common/locale';
import useUserActions from '../../_actions/user.actions';
import 'rc-slider/assets/index.css';

function Settings() {
    const user = useRecoilValue(userState);
    const savingQuotas = useRecoilValue(savingQuotasState);
    const [quotas, setQuotas] = useRecoilState(quotasState);
    const actions = useUserActions();

    const saveQuotas = () => {
        actions.updateQuotas();
    };
    const debouncedSaveQuotas = useCallback(debounce(saveQuotas, 300), []);
    useEffect(() => {
        actions.refreshMe();
    }, []);
    useEffect(() => {
        if (user) {
            setQuotas(user.quotas);
        }
    }, [user]);

    return (
        <div className="content__body quotas">
            <div className="settings__header">
                <h2 className="title">{tr('Imports and automations limits')}</h2>
            </div>
            <div className="settings__content">
                <div className="quotas__wrapper">
                    <div
                        className={`quotas__item ${
                            quotas?.daily_import_standard_conf > 100 && 'quotas__item--warning'
                        }`}
                    >
                        <div className="quotas__item__header">
                            <h4>{tr('Daily Linkedin users imports')}</h4>
                            <div className="quotas__value">{quotas?.daily_import_standard_conf}</div>
                        </div>
                        <div className="quotas__item__content">
                            <p className="quotas__item__notice">
                                {tr('To ensure account security, you should not scrape more than 100 users / day.')}
                            </p>
                            <Slider
                                min={0}
                                max={250}
                                marks={{ 0: 0, 250: 250 }}
                                step={5}
                                value={quotas?.daily_import_standard_conf || 0}
                                disabled={savingQuotas}
                                onChange={(v) => {
                                    setQuotas({ ...quotas, daily_import_standard_conf: v });
                                    debouncedSaveQuotas();
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className={`quotas__item ${
                            quotas?.daily_quota_invitations_conf > 20 && 'quotas__item--warning'
                        }`}
                    >
                        <div className="quotas__item__header">
                            <h4>{tr('Daily connection requests sent')}</h4>
                            <div className="quotas__value">{quotas?.daily_quota_invitations_conf}</div>
                        </div>
                        <div className="quotas__item__content">
                            <p className="quotas__item__notice">
                                {tr(
                                    'To ensure account security, you should not send more than 100 connection requests per week (20 per day).'
                                )}
                            </p>
                            <Slider
                                min={0}
                                max={200}
                                marks={{ 0: 0, 200: 200 }}
                                step={5}
                                value={quotas?.daily_quota_invitations_conf || 0}
                                disabled={savingQuotas}
                                onChange={(v) => {
                                    setQuotas({ ...quotas, daily_quota_invitations_conf: v });
                                    debouncedSaveQuotas();
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className={`quotas__item ${quotas?.daily_quota_messages_conf > 150 && 'quotas__item--warning'}`}
                    >
                        <div className="quotas__item__header">
                            <h4>{tr('Daily messages sent')}</h4>
                            <div className="quotas__value">{quotas?.daily_quota_messages_conf}</div>
                        </div>
                        <div className="quotas__item__content">
                            <p className="quotas__item__notice">
                                {tr('To ensure account security, you should not send more than 150 messages per day.')}
                            </p>

                            <Slider
                                min={0}
                                max={200}
                                marks={{ 0: 0, 200: 200 }}
                                step={5}
                                value={quotas?.daily_quota_messages_conf || 0}
                                disabled={savingQuotas}
                                onChange={(v) => {
                                    setQuotas({ ...quotas, daily_quota_messages_conf: v });
                                    debouncedSaveQuotas();
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className={`quotas__item ${
                            quotas?.daily_quota_openprofile_conf > 25 && 'quotas__item--warning'
                        }`}
                    >
                        <div className="quotas__item__header">
                            <h4>{tr('Daily messages sent to Open profiles')}</h4>
                            <div className="quotas__value">{quotas?.daily_quota_openprofile_conf}</div>
                        </div>
                        <div className="quotas__item__content">
                            <p className="quotas__item__notice">
                                {tr(
                                    'To ensure account security, you should not send more than 150 messages to Open profile users per week.'
                                )}
                            </p>

                            <Slider
                                min={0}
                                max={50}
                                marks={{ 0: 0, 50: 50 }}
                                step={5}
                                value={quotas?.daily_quota_openprofile_conf || 0}
                                disabled={savingQuotas}
                                onChange={(v) => {
                                    setQuotas({ ...quotas, daily_quota_openprofile_conf: v });
                                    debouncedSaveQuotas();
                                }}
                            />
                        </div>
                    </div>
                    <div className={`quotas__item ${quotas?.daily_quota_visits_conf > 80 && 'quotas__item--warning'}`}>
                        <div className="quotas__item__header">
                            <h4>{tr('Daily profile visits')}</h4>
                            <div className="quotas__value">{quotas?.daily_quota_visits_conf}</div>
                        </div>
                        <div className="quotas__item__content">
                            <p className="quotas__item__notice">
                                {tr(
                                    "To ensure account security, you should not visit more than 80 profiles per day if you're a free account and 150 if you're premium."
                                )}
                            </p>
                            <Slider
                                min={0}
                                max={200}
                                marks={{ 0: 0, 200: 200 }}
                                step={5}
                                value={quotas?.daily_quota_visits_conf || 0}
                                disabled={savingQuotas}
                                onChange={(v) => {
                                    setQuotas({ ...quotas, daily_quota_visits_conf: v });
                                    debouncedSaveQuotas();
                                }}
                            />
                        </div>
                    </div>
                    <div className={`quotas__item ${quotas?.daily_quota_follow_conf > 120 && 'quotas__item--warning'}`}>
                        <div className="quotas__item__header">
                            <h4>{tr('Daily followed users')}</h4>
                            <div className="quotas__value">{quotas?.daily_quota_follow_conf}</div>
                        </div>
                        <div className="quotas__item__content">
                            <p className="quotas__item__notice">
                                {tr('To ensure account security, you should not follow more than 120 users per day.')}
                            </p>
                            <Slider
                                min={0}
                                max={200}
                                marks={{ 0: 0, 200: 200 }}
                                step={5}
                                value={quotas?.daily_quota_follow_conf || 0}
                                disabled={savingQuotas}
                                onChange={(v) => {
                                    setQuotas({ ...quotas, daily_quota_follow_conf: v });
                                    debouncedSaveQuotas();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Settings;
