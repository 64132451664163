import { useRecoilState } from 'recoil';
import { tr } from '../../common/locale';
import { campaignState } from '../../_states/campaign.states';
import Input from '../../components/Input';
import Icon from '../../components/Icon';

function CampaignVisit({ getStepIndex }) {
    const [campaign, setCampaign] = useRecoilState(campaignState);
    const stepFrom = campaign.recipient_from_step_name;

    return (
        <div className="campaign__config">
            <h1 className="title">
                {tr('Visit Profile')}{' '}
                {campaign.action_visit && (
                    <span className="seq-activated">
                        <Icon white icon="check" />
                        {tr('Sequence added')}
                    </span>
                )}
            </h1>
            <div className="campaign__config-wrapper">
                <div className={`autom ${campaign.action_visit && 'activated'}`}>
                    <div className="autom-line stepped">
                        <div className="autom-step step-blue">
                            {campaign.action_visit && getStepIndex('action_visit')}
                        </div>
                        <span className="bullet" />
                        <div className="autom-desc">{tr('Visit the profile of column')}</div>
                        <Input disabled value={stepFrom} />
                    </div>
                    <div className={`autom-line bulleted ${!campaign.action_visit ? 'small-pad' : 'activated'}`}>
                        <span className="bullet" />
                        <div>{tr('Then move members to column')}</div>
                        <Input
                            value={campaign.action_visit_step_name}
                            disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                            onChange={(e) => setCampaign({ ...campaign, action_visit_step_name: e.target.value })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CampaignVisit;
