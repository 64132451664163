import React from 'react';
import jsxRuntime from 'react/jsx-runtime';
import moment from 'moment';
import Button from '../components/Button';
import { tr } from './locale';

const newlineRegex = /(\r\n|\r|\n)/g;

function nl2br(str) {
    if (typeof str !== 'string') {
        return str;
    }

    return str.split(newlineRegex).map((line, index) => {
        if (line.match(newlineRegex)) {
            return jsxRuntime.jsx('br', { key: index });
        }
        return line;
    });
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

function openContactSupport() {
    window.open(`${process.env.REACT_APP_DOC_URL}/support/contact/`, '_blank');
}

function openContactSales() {
    window.open(`${process.env.REACT_APP_WOOSMAP_URL}/contact/`, '_blank');
}

function deepEqual(x, y) {
    if (x === y) {
        return true;
    }
    if (typeof x === 'object' && x != null && typeof y === 'object' && y != null) {
        if (Object.keys(x).length !== Object.keys(y).length) return false;

        let isEqual = true;
        Object.keys(x).forEach((prop) => {
            if (y[prop] !== undefined) {
                if (!isEqual || !deepEqual(x[prop], y[prop])) isEqual = false;
            } else isEqual = false;
        });

        return isEqual;
    }
    console.log('connections', x, y);
    return false;
}

function getFiltersFromEncodedFilters(encodedFilters) {
    const filters = encodedFilters && encodedFilters !== 'undefined' ? encodedFilters.split(',') : [];
    return filters.map((keyValue) => {
        const [field, valueAndName] = keyValue.split(':');
        let name;
        let value;
        if (valueAndName.split('|').length === 2) {
            [value, name] = valueAndName.split('|');
        } else {
            [value] = valueAndName.split('|');
        }
        return { field, value, name };
    });
}

function searchTypeIcon(search) {
    if (search?.is_salesnav) {
        return 'list-sales-navigator';
    }
    if (search?.profiles) {
        return 'link-square';
    }
    if (search?.search_url?.indexOf('https://www.linkedin.com/events/') !== -1) {
        return 'agenda';
    }
    if (search?.is_free_search) {
        return 'list-linkedin';
    }
    return 'list-leads';
}

function getFiltersFromLocation(identifiedParams) {
    return getFiltersFromEncodedFilters(identifiedParams.get('filters'));
}

function getFiltersFromQuery(query) {
    return getFiltersFromEncodedFilters(query.filters);
}

function trialEnd(user) {
    const trialPeriod = user?.affiliate ? 30 : 7;
    if (user?.plan?.end_date) {
        return -moment().diff(user?.plan?.end_date, 'day');
    }
    return trialPeriod - moment().diff(user?.plan?.created_at, 'day');
}

function automationsHasAccess(user) {
    if (user?.plan?.has_automations) {
        return true;
    }
    if (user?.plan?.trial_date_automations) {
        return -moment().diff(user?.plan?.trial_date_automations, 'day');
    }
    return false;
}

function canReplaceVariables(message, userToSendParam) {
    const userToSend = userToSendParam.lead ? userToSendParam.lead : userToSendParam;
    const { job } = userToSend;
    const { company } = userToSend;
    const { icebreaker } = userToSendParam;
    const { location } = userToSend;
    const { industry } = userToSend;
    if (
        !job &&
        (message.indexOf('{{job_title}}') !== -1 ||
            message.indexOf('{{job title}}') !== -1 ||
            message.indexOf('{{jobtitle}}') !== -1 ||
            message.indexOf('{{Job title}}') !== -1 ||
            message.indexOf('{{Job Title}}') !== -1)
    ) {
        return false;
    }
    if (!company && (message.indexOf('{{company}}') !== -1 || message.indexOf('{{Company}}') !== -1)) {
        return false;
    }
    if (!icebreaker && (message.indexOf('{{icebreaker}}') !== -1 || message.indexOf('{{Icebreaker}}') !== -1)) {
        return false;
    }
    if (!location && (message.indexOf('{{location}}') !== -1 || message.indexOf('{{Location}}') !== -1)) {
        return false;
    }
    if (!industry && (message.indexOf('{{industry}}') !== -1 || message.indexOf('{{Industry}}') !== -1)) {
        return false;
    }
    return true;
}

function hasVariables(message) {
    return (
        message.indexOf('{{job_title}}') !== -1 ||
        message.indexOf('{{job title}}') !== -1 ||
        message.indexOf('{{jobtitle}}') !== -1 ||
        message.indexOf('{{Job title}}') !== -1 ||
        message.indexOf('{{Job Title}}') !== -1 ||
        message.indexOf('{{company}}') !== -1 ||
        message.indexOf('{{Company}}') !== -1 ||
        message.indexOf('{{icebreaker}}') !== -1 ||
        message.indexOf('{{Icebreaker}}') !== -1 ||
        message.indexOf('{{location}}') !== -1 ||
        message.indexOf('{{Location}}') !== -1 ||
        message.indexOf('{{industry}}') !== -1 ||
        message.indexOf('{{Industry}}') !== -1
    );
}

function hasOneVariable(message) {
    return (
        hasVariables(message) ||
        message.indexOf('{{firstname}}') !== -1 ||
        message.indexOf('{{first_name}}') !== -1 ||
        message.indexOf('{{first name}}') !== -1 ||
        message.indexOf('{{First name}}') !== -1 ||
        message.indexOf('{{lastname}}') !== -1 ||
        message.indexOf('{{last_name}}') !== -1 ||
        message.indexOf('{{last name}}') !== -1 ||
        message.indexOf('{{Last name}}') !== -1
    );
}

function replaceVariables(message, userToSendParam) {
    const userToSend = userToSendParam.lead ? userToSendParam.lead : userToSendParam;

    const firstname = userToSend ? userToSend.firstname || '' : '';
    const lastname = userToSend ? userToSend.lastname || '' : '';
    const job = userToSend ? userToSend.job || '' : '';
    const company = userToSend ? userToSend.company || '' : '';
    const icebreaker = userToSend ? userToSendParam.icebreaker || '' : '';
    const location = userToSend ? userToSend.location || '' : '';
    const industry = userToSend ? userToSend.industry || '' : '';
    return message
        .replaceAll('{{firstname}}', firstname)
        .replaceAll('{{first_name}}', firstname)
        .replaceAll('{{first name}}', firstname)
        .replaceAll('{{First name}}', firstname)
        .replaceAll('{{lastname}}', lastname)
        .replaceAll('{{last_name}}', lastname)
        .replaceAll('{{last name}}', lastname)
        .replaceAll('{{Last name}}', lastname)
        .replaceAll('{{job_title}}', job)
        .replaceAll('{{job title}}', job)
        .replaceAll('{{jobtitle}}', job)
        .replaceAll('{{Job title}}', job)
        .replaceAll('{{Job Title}}', job)
        .replaceAll('{{company}}', company)
        .replaceAll('{{Company}}', company)
        .replaceAll('{{icebreaker}}', icebreaker)
        .replaceAll('{{Icebreaker}}', icebreaker)
        .replaceAll('{{location}}', location)
        .replaceAll('{{Location}}', location)
        .replaceAll('{{industry}}', industry)
        .replaceAll('{{Industry}}', industry);
}

function addVariable(variable, ref, message, setMessage) {
    if (ref.current?.inputRef?.current?.selectionStart) {
        setMessage(
            `${message.slice(0, ref.current?.inputRef?.current?.selectionStart)}{{${variable}}}${message.slice(
                ref.current?.inputRef?.current?.selectionStart
            )}`
        );
    } else {
        setMessage(`${message}{{${variable}}}`);
    }
}
function variableButtons(ref, complete, message, setMessage) {
    return (
        <div className="templates__section__vars">
            <span className="templates__section__vars__info">{tr('Use variables:')}</span>
            <Button
                type="secondary"
                icon="plus"
                iconSize={12}
                label={tr('Firstname')}
                onClick={() => addVariable('firstname', ref, message, setMessage)}
            />
            <Button
                type="secondary"
                icon="plus"
                iconSize={12}
                label={tr('Lastname')}
                onClick={() => addVariable('lastname', ref, message, setMessage)}
            />
            {complete && (
                <>
                    <Button
                        type="secondary"
                        icon="plus"
                        iconSize={12}
                        label={tr('Company')}
                        onClick={() => addVariable('company', ref, message, setMessage)}
                    />
                    <Button
                        type="secondary"
                        icon="plus"
                        iconSize={12}
                        label={tr('Job title')}
                        onClick={() => addVariable('job title', ref, message, setMessage)}
                    />
                    <Button
                        type="secondary"
                        icon="plus"
                        iconSize={12}
                        label={tr('Location')}
                        onClick={() => addVariable('location', ref, message, setMessage)}
                    />
                    <Button
                        type="secondary"
                        icon="plus"
                        iconSize={12}
                        label={tr('Industry')}
                        onClick={() => addVariable('industry', ref, message, setMessage)}
                    />
                    <Button
                        type="secondary"
                        icon="plus"
                        iconSize={12}
                        label={tr('Icebreaker')}
                        onClick={() => addVariable('icebreaker', ref, message, setMessage)}
                    />
                </>
            )}
        </div>
    );
}

function updateFilter(filters, filtersToApply, fields) {
    const newFilters = [];
    filters.forEach((elem) => {
        if (fields.indexOf(elem.field) === -1) {
            newFilters.push(elem);
        }
    });

    Object.keys(filtersToApply).forEach((filterToApply) => {
        const valueAndName = `${filtersToApply[filterToApply]}`;
        let name;
        let value;
        if (valueAndName.split('|').length === 2) {
            [value, name] = valueAndName.split('|');
        } else {
            [value] = valueAndName.split('|');
        }
        if (value) newFilters.push({ field: filterToApply, value, name });
    });
    return newFilters;
}

function removeFilter(filters, fields) {
    fields.forEach((field) => {
        const filter = filters.find((item) => item.field === field);
        if (filter) {
            filters.splice(filters.indexOf(filter), 1);
        }
    });
}

function encodeFilters(filters) {
    return filters
        .map((filter) => {
            const nameFilter = filter.name ? `|${filter.name}` : '';
            return `${filter.field}:${filter.value}${nameFilter}`;
        })
        .join(',');
}

function getRandomInt(min, max) {
    const minCeil = Math.ceil(min);
    const maxCeil = Math.floor(max);
    return Math.floor(Math.random() * (maxCeil - minCeil)) + minCeil;
}

function getFilterParamFromQuery(query) {
    let filters = '';
    if (query.filters) {
        if (typeof query.filters === 'object') {
            Object.keys(query.filters).forEach((filterKey) => {
                filters += `&${filterKey}=${encodeURIComponent(query.filters[filterKey])}`;
            });
        } else if (typeof query.filters === 'string') {
            const filtersKeyValues = query.filters.split(',');
            if (filtersKeyValues) {
                filtersKeyValues.forEach((keyValue) => {
                    const [key, value] = keyValue.split(':');
                    if (key && value) {
                        filters += `&${key}=${encodeURIComponent(value)}`;
                    }
                });
            }
        }
    }
    return filters;
}

const getIntlTZ = () => Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

const checkHourFormat = (hour, defaultHour, isBlurring) => {
    const isDeleting = defaultHour && hour.length < defaultHour.length;

    if (isDeleting && hour.length === 2) {
        const [firstDigit] = hour;
        return firstDigit;
    }
    if (isBlurring) {
        switch (hour.length) {
            case 1:
                return `0${hour}:00`;
            case 3:
                return `${hour}00`;
            case 4:
                return `${hour}0`;
            default:
                break;
        }
    }

    const result = hour
        .replace('h', ':')
        .replace('H', ':')
        .replace(/[^0-9:]+/g, '');

    const splitResult = result.split(':');
    if (splitResult.length > 1) {
        splitResult[0] = splitResult[0].length === 1 ? `0${splitResult[0]}` : splitResult[0];
        splitResult[0] = splitResult[0].substring(0, 2);
        splitResult[1] = splitResult[1].length > 1 ? splitResult[1].substring(0, 2) : splitResult[1];
    }
    if (splitResult.length > 1) {
        return `${splitResult[0]}:${splitResult[1]}`;
    }
    return splitResult[0].length === 2 ? `${splitResult[0]}:` : splitResult[0];
};

const capFirstLetter = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : undefined);

const showChargebeeContent = (props) => {
    const urlParams = new URLSearchParams(props.location.search);
    return process.env.REACT_APP_CHARGEBEE_PUBLIC === 'true' || urlParams.get('chargebee') === 'true';
};

const getLastSubscription = (organization) =>
    organization.chargebeeSubscriptions?.length > 0
        ? organization.chargebeeSubscriptions.reduce((currentSub, mostRecentSub) =>
              new Date(currentSub.createdAt) > new Date(mostRecentSub.createdAt) ? currentSub : mostRecentSub
          )
        : null;

const buttonProps = {
    loading: { isLoading: true, disabled: false },
    enabled: { isLoading: false },
    disabled: { disabled: true, isLoading: false },
};

function orgChanged(props, prevProps) {
    const { match } = props;
    const { match: prevMatch } = prevProps;
    const { params } = match;
    const { params: prevParams } = prevMatch;
    const { organizationId } = params;
    const { organizationId: prevOrganizationId } = prevParams;
    return organizationId !== prevOrganizationId;
}

const getListStateToProps = (state) => ({
    results: state.results,
    query: state.query,
    loading: state.loading,
    rowCount: state.count,
});

const memoize = (jsx) => {
    const MemoizedComponent = React.memo(() => jsx);
    return <MemoizedComponent />;
};

/* i18next-extract-disable-next-line */
const unescapeValue = (str, obj) => tr(str, { ...obj, interpolation: { escapeValue: false } });

const downloadBlob = (content, filename, contentType) => {
    // Create a blob
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
};

const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
export default {
    automationsHasAccess,
    getCookie,
    searchTypeIcon,
    getRandomInt,
    nl2br,
    trialEnd,
    downloadBlob,
    getFilterParamFromQuery,
    openContactSupport,
    removeFilter,
    getFiltersFromLocation,
    getFiltersFromQuery,
    encodeFilters,
    updateFilter,
    deepEqual,
    isObject,
    getIntlTZ,
    checkHourFormat,
    capFirstLetter,
    showChargebeeContent,
    openContactSales,
    getLastSubscription,
    buttonProps,
    replaceVariables,
    canReplaceVariables,
    hasOneVariable,
    hasVariables,
    orgChanged,
    getListStateToProps,
    memoize,
    unescapeValue,
    addVariable,
    variableButtons,
};
