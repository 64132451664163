import React, { Component } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as Load } from '../icons/load.svg';
import { ReactComponent as Affiliate } from '../icons/affiliate.svg';
import { ReactComponent as Agenda } from '../icons/agenda.svg';
import { ReactComponent as Archive } from '../icons/archive.svg';
import { ReactComponent as ArchiveCircle } from '../icons/archive-circle.svg';
import { ReactComponent as ArchiveCrosshair } from '../icons/archive-crosshair.svg';
import { ReactComponent as Arobase } from '../icons/arobase.svg';
import { ReactComponent as ArrowLeftRule } from '../icons/arrow-left-rule.svg';
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg';
import { ReactComponent as ArrowRightRule } from '../icons/arrow-right-rule.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { ReactComponent as Audience } from '../icons/audience.svg';
import { ReactComponent as Bell } from '../icons/bell.svg';
import { ReactComponent as BellZ } from '../icons/bell-z.svg';
import { ReactComponent as Board } from '../icons/board.svg';
import { ReactComponent as BoardAdd } from '../icons/board-add.svg';
import { ReactComponent as BoardArrowTop } from '../icons/board-arrow-top.svg';
import { ReactComponent as BoardMinus } from '../icons/board-minus.svg';
import { ReactComponent as BoardHome } from '../icons/board-home.svg';
import { ReactComponent as BoardPlus } from '../icons/board-plus.svg';
import { ReactComponent as Book } from '../icons/book.svg';
import { ReactComponent as Case } from '../icons/case.svg';
import { ReactComponent as CaretDown } from '../icons/caret-down.svg';
import { ReactComponent as CaretLeft } from '../icons/caret-left.svg';
import { ReactComponent as CaretRight } from '../icons/caret-right.svg';
import { ReactComponent as Chart } from '../icons/chart.svg';
import { ReactComponent as Check } from '../icons/check.svg';
import { ReactComponent as CheckCircle } from '../icons/check-circle.svg';
import { ReactComponent as Checked } from '../icons/checked.svg';
import { ReactComponent as CheckSquares } from '../icons/check-squares.svg';
import { ReactComponent as CircleArrowRight } from '../icons/circle-arrow-right.svg';
import { ReactComponent as CircleArrowTopRight } from '../icons/circle-arrow-top-right.svg';
import { ReactComponent as CircleArrowBottom } from '../icons/circle-arrow-bottom.svg';
import { ReactComponent as Clock } from '../icons/clock.svg';
import { ReactComponent as Close } from '../icons/close.svg';
import { ReactComponent as CloseCircle } from '../icons/close-circle.svg';
import { ReactComponent as Cloud } from '../icons/cloud.svg';
import { ReactComponent as Cog } from '../icons/cog.svg';
import { ReactComponent as CogAlt } from '../icons/cog-alt.svg';
import { ReactComponent as Copy } from '../icons/copy.svg';
import { ReactComponent as ContactCircle } from '../icons/contact-circle.svg';
import { ReactComponent as ContactCrosshair } from '../icons/contact-crosshair.svg';
import { ReactComponent as Company } from '../icons/company.svg';
import { ReactComponent as CreditCard } from '../icons/credit-card.svg';
import { ReactComponent as Crosshair } from '../icons/crosshair.svg';
import { ReactComponent as CrosshairArrowBottom } from '../icons/crosshair-arrow-bottom.svg';
import { ReactComponent as CrosshairArrowTopRight } from '../icons/crosshair-arrow-top-right.svg';
import { ReactComponent as Delete } from '../icons/delete.svg';
import { ReactComponent as Document } from '../icons/document.svg';
import { ReactComponent as DocumentAdd } from '../icons/document-add.svg';
import { ReactComponent as Download } from '../icons/download.svg';
import { ReactComponent as CsvFile } from '../icons/csv-file.svg';
import { ReactComponent as EmptyAutomations } from '../icons/empty-automations.svg';
import { ReactComponent as EmptyAutomationsBeta } from '../icons/empty-automations-beta.svg';
import { ReactComponent as EmptyBoard } from '../icons/empty-board.svg';
import { ReactComponent as EmptyConnection } from '../icons/empty-connection.svg';
import { ReactComponent as EmptyEmailPack } from '../icons/empty-email-pack.svg';
import { ReactComponent as EmptyInbox } from '../icons/empty-inbox.svg';
import { ReactComponent as EmptyInvitation } from '../icons/empty-invitation.svg';
import { ReactComponent as EmptySentInvitation } from '../icons/empty-sent-invitation.svg';
import { ReactComponent as EmptyMessage } from '../icons/empty-message.svg';
import { ReactComponent as EmptySubscription } from '../icons/empty-subscription.svg';
import { ReactComponent as Emoji } from '../icons/emoji.svg';
import { ReactComponent as EmojiAdd } from '../icons/emoji-add.svg';
import { ReactComponent as Enrich } from '../icons/enrich.svg';
import { ReactComponent as Equalizer } from '../icons/equalizer.svg';
import { ReactComponent as Expand } from '../icons/expand.svg';
import { ReactComponent as ExpandRight } from '../icons/expand-right.svg';
import { ReactComponent as ExpandLeft } from '../icons/expand-left.svg';
import { ReactComponent as File } from '../icons/file.svg';
import { ReactComponent as FilePdf } from '../icons/file-pdf.svg';
import { ReactComponent as FolderArrow } from '../icons/folder-arrow.svg';
import { ReactComponent as Forbidden } from '../icons/forbidden.svg';
import { ReactComponent as Gift } from '../icons/gift.svg';
import { ReactComponent as Globe } from '../icons/globe.svg';
import { ReactComponent as Help } from '../icons/help.svg';
import { ReactComponent as Hide } from '../icons/hide.svg';
import { ReactComponent as Inbox } from '../icons/inbox.svg';
import { ReactComponent as InboxArchive } from '../icons/inbox-archive.svg';
import { ReactComponent as InboxPlus } from '../icons/inbox-plus.svg';
import { ReactComponent as Info } from '../icons/info.svg';
import { ReactComponent as Invoice } from '../icons/invoice.svg';
import { ReactComponent as Kanbox } from '../icons/kanbox.svg';
import { ReactComponent as Label } from '../icons/label.svg';
import { ReactComponent as LabelAdd } from '../icons/label-add.svg';
import { ReactComponent as LabelFilled } from '../icons/label-filled.svg';
import { ReactComponent as LabelManage } from '../icons/label-manage.svg';
import { ReactComponent as LabelRemove } from '../icons/label-remove.svg';
import { ReactComponent as Link } from '../icons/link.svg';
import { ReactComponent as LinkSquare } from '../icons/link-square.svg';
import { ReactComponent as Linkedin } from '../icons/linkedin.svg';
import { ReactComponent as ListCrosshair } from '../icons/list-crosshair.svg';
import { ReactComponent as ListLeads } from '../icons/list-leads.svg';
import { ReactComponent as ListLinkedin } from '../icons/list-linkedin.svg';
import { ReactComponent as ListSalesNavigator } from '../icons/list-sales-navigator.svg';
import { ReactComponent as List } from '../icons/list.svg';
import { ReactComponent as ListAdd } from '../icons/list-add.svg';
import { ReactComponent as Login } from '../icons/login.svg';
import { ReactComponent as Logout } from '../icons/logout.svg';
import { ReactComponent as Magic } from '../icons/magic.svg';
import { ReactComponent as Mail } from '../icons/mail.svg';
import { ReactComponent as MailOpened } from '../icons/mail-opened.svg';
import { ReactComponent as Menu } from '../icons/menu.svg';
import { ReactComponent as MenuHorizontal } from '../icons/menu-horizontal.svg';
import { ReactComponent as Message } from '../icons/message.svg';
import { ReactComponent as MessageAdd } from '../icons/message-add.svg';
import { ReactComponent as MessageCheck } from '../icons/message-check.svg';
import { ReactComponent as MessageMulti } from '../icons/message-multi.svg';
import { ReactComponent as MessageRemove } from '../icons/message-remove.svg';
import { ReactComponent as Minus } from '../icons/minus.svg';
import { ReactComponent as Money } from '../icons/money.svg';
import { ReactComponent as Moon } from '../icons/moon.svg';
import { ReactComponent as Move } from '../icons/move.svg';
import { ReactComponent as Network } from '../icons/network.svg';
import { ReactComponent as NoLabel } from '../icons/no-label.svg';
import { ReactComponent as Note } from '../icons/note.svg';
import { ReactComponent as Notebook } from '../icons/notebook.svg';
import { ReactComponent as Padlock } from '../icons/padlock.svg';
import { ReactComponent as Pause } from '../icons/pause.svg';
import { ReactComponent as PaperClip } from '../icons/paperclip.svg';
import { ReactComponent as Pen } from '../icons/pen.svg';
import { ReactComponent as Picture } from '../icons/picture.svg';
import { ReactComponent as Pin } from '../icons/pin.svg';
import { ReactComponent as PinAdd } from '../icons/pin-add.svg';
import { ReactComponent as PinReverse } from '../icons/pin-reverse.svg';
import { ReactComponent as PlanGrowth } from '../icons/plan-growth.svg';
import { ReactComponent as PlanGrowthAppsumo } from '../icons/plan-growth-appsumo.svg';
import { ReactComponent as PlanNurtur } from '../icons/plan-nurtur.svg';
import { ReactComponent as PlanNurturAppsumo } from '../icons/plan-nurtur-appsumo.svg';
import { ReactComponent as PlanPackExport } from '../icons/plan-pack-export.svg';
import { ReactComponent as PlanPackExportEnrich } from '../icons/plan-pack-export-enrich.svg';
import { ReactComponent as PlanScale } from '../icons/plan-scale.svg';
import { ReactComponent as PlanScaleAppsumo } from '../icons/plan-scale-appsumo.svg';
import { ReactComponent as PlaySquare } from '../icons/play-square.svg';
import { ReactComponent as Plus } from '../icons/plus.svg';
import { ReactComponent as PlusCircle } from '../icons/plus-circle.svg';
import { ReactComponent as Post } from '../icons/post.svg';
import { ReactComponent as PostAdd } from '../icons/post-add.svg';
import { ReactComponent as PostRemove } from '../icons/post-remove.svg';
import { ReactComponent as Private } from '../icons/private.svg';
import { ReactComponent as Profile } from '../icons/profile.svg';
import { ReactComponent as Reduce } from '../icons/reduce.svg';
import { ReactComponent as ReduceLeft } from '../icons/reduce-left.svg';
import { ReactComponent as ReduceRight } from '../icons/reduce-right.svg';
import { ReactComponent as Rocket } from '../icons/rocket.svg';
import { ReactComponent as SalesNavigator } from '../icons/sales-navigator.svg';
import { ReactComponent as Sandwich } from '../icons/sandwich.svg';
import { ReactComponent as Save } from '../icons/save.svg';
import { ReactComponent as Search } from '../icons/search.svg';
import { ReactComponent as Send } from '../icons/send.svg';
import { ReactComponent as Share } from '../icons/share.svg';
import { ReactComponent as Show } from '../icons/show.svg';
import { ReactComponent as Star } from '../icons/star.svg';
import { ReactComponent as StarRemove } from '../icons/star-remove.svg';
import { ReactComponent as StarFilled } from '../icons/star-filled.svg';
import { ReactComponent as Sticker } from '../icons/sticker.svg';
import { ReactComponent as Stop } from '../icons/stop.svg';
import { ReactComponent as Sun } from '../icons/sun.svg';
import { ReactComponent as Sync } from '../icons/sync.svg';
import { ReactComponent as TrashCan } from '../icons/trash-can.svg';
import { ReactComponent as Unarchive } from '../icons/unarchive.svg';
import { ReactComponent as UnarchiveCircle } from '../icons/unarchive-circle.svg';
import { ReactComponent as UnarchiveCrosshair } from '../icons/unarchive-crosshair.svg';
import { ReactComponent as Undo } from '../icons/undo.svg';
import { ReactComponent as User } from '../icons/user.svg';
import { ReactComponent as UserAdd } from '../icons/user-add.svg';
import { ReactComponent as UserArrowLeft } from '../icons/user-arrow-left.svg';
import { ReactComponent as UserArrowLeftClose } from '../icons/user-arrow-left-close.svg';
import { ReactComponent as UserArrowRight } from '../icons/user-arrow-right.svg';
import { ReactComponent as UserArrowRightClose } from '../icons/user-arrow-right-close.svg';
import { ReactComponent as UserCheck } from '../icons/user-check.svg';
import { ReactComponent as UserRemove } from '../icons/user-remove.svg';
import { ReactComponent as UserEye } from '../icons/user-eye.svg';
import { ReactComponent as UserCrosshair } from '../icons/user-crosshair.svg';
import { ReactComponent as UserSquares } from '../icons/user-squares.svg';
import { ReactComponent as Users } from '../icons/users.svg';
import { ReactComponent as UsersMessage } from '../icons/users-message.svg';
import { ReactComponent as Video } from '../icons/video.svg';
import { ReactComponent as Webhook } from '../icons/webhook.svg';

const Icons = {
    load: Load,
    affiliate: Affiliate,
    agenda: Agenda,
    archive: Archive,
    'archive-circle': ArchiveCircle,
    'archive-crosshair': ArchiveCrosshair,
    arobase: Arobase,
    'arrow-left-rule': ArrowLeftRule,
    'arrow-left': ArrowLeft,
    'arrow-right-rule': ArrowRightRule,
    'arrow-right': ArrowRight,
    audience: Audience,
    bell: Bell,
    'bell-z': BellZ,
    board: Board,
    'board-add': BoardAdd,
    'board-arrow-top': BoardArrowTop,
    'board-minus': BoardMinus,
    'board-home': BoardHome,
    'board-plus': BoardPlus,
    book: Book,
    case: Case,
    'caret-down': CaretDown,
    'caret-left': CaretLeft,
    'caret-right': CaretRight,
    chart: Chart,
    check: Check,
    'check-circle': CheckCircle,
    checked: Checked,
    'check-squares': CheckSquares,
    'circle-arrow-right': CircleArrowRight,
    'circle-arrow-top-right': CircleArrowTopRight,
    'circle-arrow-bottom': CircleArrowBottom,
    clock: Clock,
    close: Close,
    'close-circle': CloseCircle,
    cloud: Cloud,
    cog: Cog,
    'cog-alt': CogAlt,
    copy: Copy,
    'contact-circle': ContactCircle,
    'contact-crosshair': ContactCrosshair,
    company: Company,
    'credit-card': CreditCard,
    crosshair: Crosshair,
    'crosshair-arrow-bottom': CrosshairArrowBottom,
    'crosshair-arrow-top-right': CrosshairArrowTopRight,
    delete: Delete,
    document: Document,
    'document-add': DocumentAdd,
    download: Download,
    'csv-file': CsvFile,
    'empty-automations': EmptyAutomations,
    'empty-automations-beta': EmptyAutomationsBeta,
    'empty-board': EmptyBoard,
    'empty-connection': EmptyConnection,
    'empty-email-pack': EmptyEmailPack,
    'empty-inbox': EmptyInbox,
    'empty-invitation': EmptyInvitation,
    'empty-sent-invitation': EmptySentInvitation,
    'empty-message': EmptyMessage,
    'empty-subscription': EmptySubscription,
    enrich: Enrich,
    equalizer: Equalizer,
    expand: Expand,
    'expand-left': ExpandLeft,
    'expand-right': ExpandRight,
    file: File,
    'file-pdf': FilePdf,
    'folder-arrow': FolderArrow,
    forbidden: Forbidden,
    gift: Gift,
    globe: Globe,
    help: Help,
    hide: Hide,
    emoji: Emoji,
    'emoji-add': EmojiAdd,
    inbox: Inbox,
    'inbox-archive': InboxArchive,
    info: Info,
    'inbox-plus': InboxPlus,
    invoice: Invoice,
    kanbox: Kanbox,
    label: Label,
    'label-add': LabelAdd,
    'label-filled': LabelFilled,
    'label-manage': LabelManage,
    'label-remove': LabelRemove,
    link: Link,
    'link-square': LinkSquare,
    linkedin: Linkedin,
    list: List,
    'list-crosshair': ListCrosshair,
    'list-leads': ListLeads,
    'list-linkedin': ListLinkedin,
    'list-sales-navigator': ListSalesNavigator,
    'list-add': ListAdd,
    login: Login,
    logout: Logout,
    magic: Magic,
    mail: Mail,
    'mail-opened': MailOpened,
    menu: Menu,
    'menu-horizontal': MenuHorizontal,
    message: Message,
    'message-add': MessageAdd,
    'message-check': MessageCheck,
    'message-multi': MessageMulti,
    'message-remove': MessageRemove,
    minus: Minus,
    money: Money,
    moon: Moon,
    move: Move,
    network: Network,
    'no-label': NoLabel,
    note: Note,
    notebook: Notebook,
    padlock: Padlock,
    pause: Pause,
    paperclip: PaperClip,
    pen: Pen,
    picture: Picture,
    pin: Pin,
    'pin-add': PinAdd,
    'pin-reverse': PinReverse,
    'plan-growth': PlanGrowth,
    'plan-growth-appsumo': PlanGrowthAppsumo,
    'plan-nurtur': PlanNurtur,
    'plan-nurtur-appsumo': PlanNurturAppsumo,
    'plan-pack-export': PlanPackExport,
    'plan-pack-export-enrich': PlanPackExportEnrich,
    'plan-scale': PlanScale,
    'plan-scale-appsumo': PlanScaleAppsumo,
    'play-square': PlaySquare,
    plus: Plus,
    'plus-circle': PlusCircle,
    post: Post,
    'post-add': PostAdd,
    'post-remove': PostRemove,
    private: Private,
    profile: Profile,
    reduce: Reduce,
    'reduce-left': ReduceLeft,
    'reduce-right': ReduceRight,
    rocket: Rocket,
    'sales-navigator': SalesNavigator,
    sandwich: Sandwich,
    save: Save,
    search: Search,
    send: Send,
    share: Share,
    show: Show,
    star: Star,
    'star-remove': StarRemove,
    'star-filled': StarFilled,
    sticker: Sticker,
    stop: Stop,
    sun: Sun,
    sync: Sync,
    'trash-can': TrashCan,
    unarchive: Unarchive,
    'unarchive-circle': UnarchiveCircle,
    'unarchive-crosshair': UnarchiveCrosshair,
    undo: Undo,
    user: User,
    'user-add': UserAdd,
    'user-arrow-left': UserArrowLeft,
    'user-arrow-left-close': UserArrowLeftClose,
    'user-arrow-right': UserArrowRight,
    'user-arrow-right-close': UserArrowRightClose,
    'user-check': UserCheck,
    'user-remove': UserRemove,
    'user-eye': UserEye,
    'user-crosshair': UserCrosshair,
    'user-squares': UserSquares,
    users: Users,
    'users-message': UsersMessage,
    video: Video,
    webhook: Webhook,
};

class Icon extends Component {
    render() {
        const {
            isPlan,
            isEmpty,
            fill,
            fillColor,
            isLoad,
            icon,
            size,
            stroke,
            className,
            light,
            white,
            blue,
            green,
            red,
            yellow,
            title,
            isProcessing,
            testId,
        } = this.props;
        const IconComponent = Object.keys(Icons).includes(icon) ? Icons[icon] : Kanbox;
        const classes = cl(
            'icon',
            { 'icon--empty': isEmpty },
            { 'icon--plan': isPlan },
            { 'icon--fill': !!fill },
            { 'icon--load': isLoad },
            { 'icon--light': light },
            { 'icon--white': white },
            { 'icon--red': red },
            { 'icon--green': green },
            { 'icon--blue': blue },
            { 'icon--yellow': yellow },
            { 'icon--processing': isProcessing },
            className
        );
        const style = fillColor ? { fill: fillColor } : undefined;
        return (
            <IconComponent
                className={classes}
                data-testid={`${testId}-${icon}`}
                width={size}
                height={size}
                title={title}
                strokeWidth={stroke}
                style={style}
            />
        );
    }
}

Icon.defaultProps = {
    size: 22,
    isPlan: undefined,
    isEmpty: undefined,
    className: null,
    fill: undefined,
    fillColor: undefined,
    stroke: 1.2,
    light: false,
    white: false,
    red: false,
    green: false,
    blue: false,
    title: null,
    isProcessing: null,
    testId: 'icon',
};

Icon.propTypes = {
    size: PropTypes.number,
    className: PropTypes.string,
    fill: PropTypes.bool,
    isPlan: PropTypes.bool,
    isEmpty: PropTypes.bool,
    fillColor: PropTypes.string,
    stroke: PropTypes.number,
    light: PropTypes.bool,
    white: PropTypes.bool,
    red: PropTypes.bool,
    green: PropTypes.bool,
    blue: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string,
    isProcessing: PropTypes.bool,
    testId: PropTypes.string,
};

export default Object.assign(Icon, {
    Icons,
});
